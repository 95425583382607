import React from "react";

const Modal = ({ children, handleClick }: any) => {
  return (
    <div
      className="absolute top-0 left-0 bottom-0 right-0 overflow-hidden w-full h-full bg-black bg-opacity-70 z-50 flex flex-col justify-end"
      onClick={handleClick}
    >
      <div
        onClick={($event) => {
          $event.stopPropagation();
        }}
        className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8 overflow-auto"
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
