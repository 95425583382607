import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOperation } from "../../store";
import { AccountType } from "../../models";
import LoaderImg from "../../assets/img/loader.gif";
import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";

const ScanResult = () => {
  const navigate = useNavigate();
  const { pay, transfert, withdraw, deposit } = useOperation();
  const { getReceiver } = useOperation();
  const [accountType, setAccountType] = useState<AccountType>(
    window.sessionStorage.getItem("nj_accountType") as AccountType
  );
  const [operationAccountType, setOperationAccountType] = useState<AccountType>(
    AccountType.CLIENT
  );
  const [profile, setProfile] = useState();
  const { readInvoiceQrcode } = useOperation();
  const [displayInvoiceModal, setDisplayInvoiceModal] =
    useState<boolean>(false);
  useState<boolean>(false);
  const [invoiceResponse, setInvoiceResponse] = useState<any>();
  const [invoice, setInvoice] = useState<any>();
  const [password, setPassword] = useState("");
  const [receiver, setReceiver] = useState<any>();
  const [displayAmountModal, setDisplayAmountModal] = useState(false);
  const [displayOperationsModal, setDisplayOperationsModal] = useState(false);

  const [displayPasswordModal, setDisplayPasswordModal] = useState(false);
  const [displayResModal, setDisplayResModal] = useState(false);
  const [loader, setLoader] = useState<{ timeout: number; message: string }>();
  const [response, setResponse] = useState<{
    success: boolean;
    message: string;
  }>();
  const [amount, setAmount] = useState(0);
  const [operationType, setOperationType] = useState<any>();
  const [operationTypeName, setOperationTypeName] = useState<any>();
  const [options, setOptions] = useState<any[]>([]);

  const operations = [
    {
      display_name: "transfert",
      icon: null,
      value: "transfert",
      receiver_types: [AccountType.CLIENT],
      sender_types: [AccountType.CLIENT],
    },
    {
      display_name: "dépot",
      value: "deposit",
      receiver_types: [AccountType.CLIENT, AccountType.BUSINESS],
      sender_types: [AccountType.kIOSQUE, AccountType.KIOSQUE_AGENT],
    },
    {
      display_name: "retrait",
      value: "withdraw",
      receiver_types: [AccountType.kIOSQUE, AccountType.KIOSQUE_AGENT],
      sender_types: [AccountType.CLIENT, AccountType.BUSINESS],
    },
    {
      display_name: "paiement",
      value: "payment",
      receiver_types: [AccountType.BUSINESS, AccountType.BUSINESS_AGENT],
      sender_types: [AccountType.CLIENT, AccountType.BUSINESS],
    },
  ];

  useEffect(() => {
    const result = window.sessionStorage.getItem("nj_scan_result");
    console.log(result);
    if (!result) return;
    readQR(result);
    // findUser(result);
  }, []);

  const readQR = async (code: string) => {
    const res = await readInvoiceQrcode(code);
    console.log(res);
    setInvoiceResponse(res);
     console.log(res);
     if(!res) return
    if (
      res.type === "invoice" &&
      res.transaction_type === "paiement"
    ) {
      setOperationType(res?.transaction_type);
      const operationName = operations.find(operation => operation.value === res.transaction_type)?.display_name;
      setOperationTypeName(operationName)
      setDisplayInvoiceModal(true);
      return;
    } else if (res.type === "account") {
      await onValidateIdentifier(res);
    }

    // setProfile(user);
  };

  const onMakeRequest = () => {
    navigate("/dashboard/home");
  };

  const onPayInvoice = async () => {
    // displayLoader();
    // if (!receiver) {
    //   const res: any = await payInvoice(password, invoice.code);
    //   setResponse({ success: res.res === "Success", message: res.message });

    //   return setDisplayResModal(true);
    // }
    const res = await pay(
      invoiceResponse.transaction_type,
      invoiceResponse.receiver_id,
      invoiceResponse.amount,
      password,
      invoiceResponse.code
    );
    // setDisplayResModal(true);
    console.log(res);
  };

  const onValidateIdentifier = async (invoiceResponse: any) => {
    console.log("runed");
    displayLoader();

    let req: any = {};
    console.log("reciever rsponse", invoiceResponse);
    if (invoiceResponse.receiver_pseudo)
      req["receiver_pseudo"] = invoiceResponse.receiver_pseudo;
    else {
      req = {
        receiver_id: invoiceResponse.receiver_id,
        receiver_type: invoiceResponse.receiver_type,
      };
    }
    const receiver = await getReceiver(req);

    setReceiver(receiver);
    const options = operations.filter(
      (operation) =>
        operation.sender_types.includes(accountType) &&
        operation.receiver_types.includes(invoiceResponse.receiver_type)
    );
    console.log(options);
    setOptions(options);
    // display options
    if (!options.length) {
      // display error message "Aucune opération possible pour ce qr code"
      setResponse({ success: false, message: "Aucune opération possible pour ce qr code" });
      return setDisplayResModal(true);
    }

    if (options.length === 1) {
      setOperationType(options[0].value);
      console.log('options', options[0].value)
      const operationName = operations.find(operation => operation.value === options[0].value)?.display_name;
      setOperationTypeName(operationName);
      setDisplayAmountModal(true);
    }

    //  display options modal
  };

  const selectOperation = async () => {};

  const onValidateAmount = () => {
    displayLoader();
    setDisplayAmountModal(false);
    setDisplayPasswordModal(true);
  };

  const onMakeOperation = async () => {
    // if (accountType === AccountType.kIOSQUE)
    //   return await onMakeKioskOperation();
    displayLoader();
    console.log(receiver, amount, password);
    setDisplayPasswordModal(false);
    let res;
    switch (operationType) {
      case "transfert":
        res = await transfert(
          AccountType.CLIENT,
          receiver.id,
          amount,
          password
        );
        break;
      case "deposit":
        res = await deposit(AccountType.kIOSQUE, receiver.id, amount, password);
        break;
      case "withdraw":
        res = await withdraw(
          AccountType.kIOSQUE,
          receiver.id,
          amount,
          password
        );
        break;
      case "payment":
        res = await pay(AccountType.BUSINESS, receiver.id, amount, password);
        break;
      default:
        console.log("Operation not supported!");
    }
    console.log(res);
    setResponse(res);
    setDisplayResModal(true);
  };

  const displayLoader = (
    message = "Veuillez patienter...",
    timeout = 3,
    cb?: () => void
  ) => {
    setLoader({ timeout, message });
    window.setTimeout(() => dismissLoader(), timeout * 1000);
  };

  const dismissLoader = () => {
    setLoader(undefined);
  };

  const goBack = () => {
    navigate('/dashboard/home')
  };



  return (
    <>
      {displayInvoiceModal ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayInvoiceModal(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            {" "}
            <h2 className="text-lg uppercase font-bold">
              Détails de la facture
            </h2>
            <h5 className="text-sm">
              {" "}
              Voulez-vous payer cette facture de{" "}
              <span className="text-tertiary">
                {invoiceResponse.amount} FCFA
              </span>{" "}
              à{" "}
              <span className="text-primary text-sm">
                {invoiceResponse.name}{" "}
              </span>
              ?
            </h5>
            <input
              onChange={($event) => setPassword($event.target.value)}
              type="password"
              placeholder="Votre mot de passe"
              className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
            />
            <button
              disabled={!password}
              onClick={onPayInvoice}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
            >
              Payer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Amount */}
      {displayOperationsModal ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayOperationsModal(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            <h2 className="text-lg uppercase font-bold">
              Chosissez l'opération à faire <br />
            </h2>
            <h5 className="text-sm">
              {" "}
              Voulez-vous effectuer une opération de {operationTypeName}  à {" "}
              <span className="text-primary text-sm">{receiver.name} </span>?
            </h5>
            <input
              onChange={($event) => setAmount(+$event.target.value)}
              type="number"
              placeholder="Montant en F CFA"
              className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
            />
            <button
              disabled={!amount}
              onClick={onValidateAmount}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
            >
              Effectuer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Amount */}
      {displayAmountModal ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayAmountModal(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2>
            <h5 className="text-sm">
              {" "}
              Voulez-vous effectuer cette opération de {operationTypeName} à{" "}
              <span className="text-primary text-sm">{receiver.name} </span>?
            </h5>
            <input
              onChange={($event) => setAmount(+$event.target.value)}
              type="number"
              placeholder="Montant en F CFA"
              className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
            />
            <button
              disabled={!amount}
              onClick={onValidateAmount}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
            >
              Effectuer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Pin */}
      {displayPasswordModal ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayPasswordModal(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            {" "}
            <h2 className="text-lg uppercase font-bold">Mot de passe</h2>
            <h5 className="text-sm">
              {" "}
              Voulez-vous effectuer cette opération de{" "}
              <span className="text-tertiary">{amount}</span> avec{" "}
              <span className="text-primary text-sm">{receiver.name} </span>?
            </h5>
            <input
              onChange={($event) => setPassword($event.target.value)}
              type="password"
              placeholder="Votre mot de passe"
              className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
            />
            <button
              disabled={!password}
              onClick={onMakeOperation}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
            >
              Confimer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* loader */}
      {loader ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayResModal(false);
          }}
        >
          <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
            {/* <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2> */}

            <img src={LoaderImg} />
            <p className="text-gray-500 italic text-sm">{loader?.message}</p>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Response */}
      {displayResModal ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayResModal(false);
          }}
        >
          <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
            {/* <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2> */}
            {response?.success ? (
              <CheckCircleIcon className="w-32 h-32 text-secondary" />
            ) : (
              <>
                {" "}
                <InformationCircleIcon className="w-32 h-32 text-red-500" />
                <p className="text-red-500 italic text-sm">
                  {response?.message}
                </p>
              </>
            )}

            <button className="bg-tertiary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark">
              Terminer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="w-full h-full flex justify-center items-center bg-primary">
        {/* <button
          disabled={!profile}
          onClick={onMakeRequest}
          className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full text-dark"
        >
          Continuer
        </button> */}
        <button
          onClick={goBack}
          className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full text-dark"
        >
          Continuer
        </button>
      </div>
    </>
  );
};

export default ScanResult;
