import React, { useEffect, useState } from "react";
import LogoImg from "../../assets/img/logo.png";
import Modal from "../../components/utils/Modal";
import { useEmployement } from "../../store";
import {
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
const Employees = () => {
  const [employer, setEmployer] = useState(true);
  const [employerModal, setEmployerModal] = useState(false);
  const [salaryType, setSalaryType] = useState<"manual" | "automatic" | any>(
    "manual"
  );
  const [frequency, setFrequency] = useState<
    "monthly" | "weekly" | "bi-weekly" | any
  >("monthly");
  const [nextPaymentDate, setNextPaymentDate] = useState(new Date());
  const [salarierModal, setSalarierModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [response, setResponse] = useState<any>();
  const [employees, setEmployees] = useState<any[]>([]);

  //form
  const [id, setId] = useState("");
  const [salary, setSalary] = useState<number | null>(null);
  const {
    getEmployer,
    becomeEmployer,
    addEmployee,
    getEmployees,
    payManySalaries,
    paySalary,
  } = useEmployement();
  const [password, setPassword] = useState("");
  let action: any = () => {
    console.log("action");
  };

  useEffect(() => {
    // fetch employer
    loadEmployer();
    // fetch employees
    loadEmployees();
  }, []);

  const handleBecomeEmployer = async () => {
    // become employer
    const response = await becomeEmployer({
      salary_type: salaryType,
      frequency,
      next_payment_date: nextPaymentDate.toISOString(),
      password,
    });
    setEmployerModal(false);
    console.log(response);
    setResponse(response);
    setTimeout(() => {
      setResponse(null);
    }, 3000);
  };

  const loadEmployer = async () => {
    const { data } = await getEmployer();
    console.log(data);
    setEmployer(data);
  };

  const loadEmployees = async () => {
    const { data } = await getEmployees();
    console.log(data);
    setEmployees(data.map((employee: any) => ({ ...employee, checked: true })));
  };

  const handleAddEmployee = async () => {
    // add employee
    if (!id || !salary) return;
    const response = await addEmployee({ employee_id: id, salary });
    setSalarierModal(false);
    console.log(response);
    setResponse(response);
    setTimeout(() => {
      setResponse(null);
    }, 3000);
  };

  const payManyEmployees = async () => {
    const ids = employees
      .filter((employee) => employee.checked)
      .map((employee) => employee.id);

    if (!password || !ids.length) return;

    // pay all employees
    action = payManySalaries({
      receiver_ids: ids,
      password,
      description: "description",
    });

    setPasswordModal(true);
  };

  const payEmployee = async (id: string) => {
    // pay employee
    action = () =>
      paySalary({
        receiver_id: id,
        password,
        amount: 240000,
        description: "",
      });
    setPasswordModal(true);
  };

  const confirmAction = async () => {
    setPasswordModal(false);
    const response = await action();
    setResponse(response);
    setTimeout(() => {
      setResponse(null);
    }, 3000);
  };

  const handleCheck = (id: string) => {
    setEmployees((prev) =>
      prev.map((employee) =>
        employee.id === id
          ? { ...employee, checked: !employee.checked }
          : employee
      )
    );
  };

  return (
    <div className="w-full pt-4 px-4 h-[calc(100vh-120px)] bg-slate-50">
      {/* if not employer Static card with button become employer else display list of employers */}
      {!employer ? (
        <div className="w-ful p-4 bg-secondary shadow-md rounded-lg space-y-4">
          <h3 className="text-xl  text-light"> Hi Opticodev!</h3>
          <p className="text-light">
            Devenez employeur, rajoutez vos salariés et payez les via NamDje.
          </p>
          <div className="w-full text-end">
            <button className="bg-tertiary p-2 rounded">Commencer</button>
          </div>
        </div>
      ) : (
        <div className="space-y-8">
          <div className="w-ful p-4 bg-secondary shadow-md rounded-lg space-y-4">
            <h3 className="text-xl  text-light"> Hi Opticodev!</h3>
            <p className="text-light">
              Vous avez 4 employés avec une masse salaire de{" "}
              <span className="italic"> 96 0000 FCFA</span>
            </p>
            <div className="w-full flex justify-between">
              <button
                onClick={() => setSalarierModal(true)}
                className="bg-tertiary p-2 rounded"
              >
                Nouveau salarié
              </button>
              <button
                onClick={() => payManyEmployees()}
                className="bg-tertiary p-2 rounded"
              >
                Virer en masse({employees.filter((e) => e.checked).length})
              </button>
            </div>
          </div>

          <div className="w-full spacey-2 ">
            {employees.map((employee, idx) => (
              <div key={idx} className="w-full flex justify-between">
                <div className="flex gap-4 items-center">
                  <div className="">
                    <input
                      onChange={() => handleCheck(employee.id)}
                      type="checkbox"
                      defaultChecked={employee.checked}
                    />
                  </div>
                  <img className="w-16 rounded-full" src={LogoImg} alt="" />
                  <div>
                    <h3 className="font-bold">ABALO Koffi</h3>
                    <p>240 000 FCFA</p>
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => payEmployee(employee.id)}
                    className="bg-tertiary p-2 rounded"
                  >
                    Virer
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {employerModal && (
        <Modal>
          <div className="w-full text-left">
            <button
              onClick={() => setEmployerModal((prev) => !prev)}
              className="p-2 font-bold text-red-500"
            >
              Annuler
            </button>
            <div className="w-full flex flex-col gap-4 ">
              <div className="w-full ">
                <label className="text-lg text-primary font-bold">
                  Mode de paiement
                </label>
                <div className="p-2">
                  <select
                    onChange={(e) => setSalaryType(e.target.value)}
                    className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
                    name="salary_type"
                  >
                    <option value="manual">Manuel</option>
                    <option value="automatic">Automatique</option>
                  </select>
                </div>
              </div>

              <div className="w-full ">
                <label className="text-lg text-primary font-bold">
                  Fréquence de paiement
                </label>
                <div className="p-2">
                  <select
                    onChange={(e) => setFrequency(e.target.value)}
                    className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
                    name="salary_type"
                  >
                    <option value="monthly">Mensuel</option>
                    <option value="weekly">Hebdomadaire</option>
                    <option value="bi-weekly">Bi-hebdomadaire</option>
                  </select>
                </div>
              </div>

              <div className="w-full ">
                <label className="text-lg text-primary font-bold">
                  Prochain date de virement
                </label>
                <div className="p-2">
                  <input
                    onChange={(e) => setId(e.target.value)}
                    className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
                    name="next_payment_date"
                    type="date"
                    placeholder=""
                  />
                </div>
              </div>
              <div className="flex justify-center w-full px-2 pt-4">
                <button
                  onClick={handleAddEmployee}
                  className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {salarierModal && (
        <Modal>
          <div className="w-full text-left">
            <button
              onClick={() => setSalarierModal((prev) => !prev)}
              className="p-2 font-bold text-red-500"
            >
              Annuler
            </button>
          </div>

          <div className="w-full flex flex-col gap-4 ">
            <div className="w-full ">
              <label className="text-lg text-primary font-bold">
                Numéro de téléphone
              </label>
              <div className="p-2">
                <input
                  onChange={(e) => setId(e.target.value)}
                  className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
                  name="employee_id"
                  type="tel"
                  placeholder="Entrer le numero"
                />
              </div>
            </div>

            <div className="w-full">
              <label className="text-lg text-primary font-bold">
                Entrer le salaire
              </label>
              <div className="p-1">
                <input
                  onChange={(e) => setSalary(+e.target.value)}
                  className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
                  type="number"
                  name="amount"
                />
              </div>
            </div>

            <div className="w-full flex justify-center">
              <button
                onClick={handleAddEmployee}
                className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full text-dark"
              >
                Valider
              </button>
            </div>
          </div>
        </Modal>
      )}

      {passwordModal && (
        <Modal>
          <div className="w-full text-left">
            <button
              onClick={() => setPasswordModal((prev) => !prev)}
              className="p-2 font-bold text-red-500"
            >
              Annuler
            </button>
            <div className="w-full p-4 space-y-4">
              <input
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                type="password"
                placeholder="Votre mot de passe"
                className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary p-6 rounded h-10 text-primary text-lg "
              />
              <div className="flex justify-center w-full px-2 pt-4">
                <button
                  onClick={confirmAction}
                  className="bg-secondary px-4 py-2 rounded text-white w-full"
                  type="submit"
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {response && (
        <Modal>
          <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
            {response.success ? (
              <>
                {" "}
                <CheckCircleIcon className="w-32 h-32 text-secondary" />
                <p className="text-secondary italic text-sm">
                  Votre tontine a été créée avec succès. Vous pouvez maintenant
                  inviter des membres
                </p>
              </>
            ) : (
              <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
                {" "}
                <InformationCircleIcon className="w-32 h-32 text-red-500" />
                <p className="text-red-500 italic text-sm">
                  {response?.data?.message || "Une erreur s' est produite!"}
                </p>
                {/* <button className="bg-tertiary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark">
               Terminer
             </button> */}
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Employees;
