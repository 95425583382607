import React from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";
import { useNavigate } from "react-router-dom";

const Scan = () => {
  const navigate = useNavigate();

  const onResult = (result: string) => {
    console.log(result);
    if (!result) return;
    window.sessionStorage.setItem("nj_scan_result", result);
    navigate("/dashboard/result");
  };

  return (
    <div className="w-full h-full flex justify-center items-center bg-transparent">
      <QrScanner
        onDecode={onResult}
        onError={(error: any) => console.log(error?.message)}
      />
    </div>
  );
};

export default Scan;
