import { BellIcon, ChartBarIcon, HomeIcon, UserIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useAuth, useNotification } from "../store";
import { AccountType, IProfile } from "../models";

const MobilPrivateLayout = () => {
    const { getProfile } = useAuth();
  const navigate = useNavigate()
  const [accountType] = useState<AccountType>(
    window.sessionStorage.getItem("nj_accountType") as AccountType
  );
  const [profile, setProfile ] = useState<IProfile>();
  const { getFcmToken } = useNotification()

  useEffect(() => {
    const localProfile = getProfile();
    getFcmToken()
    // console.log(profile);
    localProfile ? setProfile(localProfile) : navigate("/");
  }, []);
  return (
    <div className="hidden md:flex h-screen w-full ">
    <div className="">
    </div>
    <div className={`${accountType === AccountType.CLIENT?"bg-primary":accountType===AccountType.BUSINESS?"bg-tertiary":"bg-teal-300"} flex flex-col space-y-14 min-w-[13vw]  text-white pt-10 pl-4 `}>
      <NavLink
        to="/dashboard/home"
        className="flex items-center px-4 py-2 gap-2 "
      >
        <HomeIcon className="w-8" />
        <h3 className="text-lg  leading-none">Acccueil</h3>
      </NavLink>
      <NavLink
        to="/dashboard/historique"
        className="flex items-center px-4 py-2 gap-2 "
      >
        <ChartBarIcon className="w-8" />
        <h3 className="text-lg  leading-none">Historique</h3>
      </NavLink>
      {/* <div className="relative -top-12 bg-transprent p-1 pl-2  rounded-full w-16 h-16">
      <NavLink
        to="/dashboard/scan"
        className="h-full bg-tertiary rounded-full text-light  flex flex-col items-center justify-around active:text-primary"
      >
        <QrCodeIcon className="w-8" />
      </NavLink>
      </div> */}
    
      <NavLink
        to="/dashboard/notifications"
        className="flex items-center px-4 py-2 gap-2 "
      >
        <BellIcon className="w-8" />
        <h3 className="text-lg  leading-none">Notifications</h3>
      </NavLink>
      <NavLink
        to="/dashboard/profile"
        className="flex items-center px-4 py-2 gap-2 "
      >
        <UserIcon className="w-8" />
        <h3 className="text-lg leading-none">Profil</h3>
      </NavLink>
    </div>
  {/* layout section */}
  <Outlet />
  </div>  )
}

export default MobilPrivateLayout