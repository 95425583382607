import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./configs/firebase";
import AuthLayout from "./layouts/AuthLayout";
import AccountTypes from "./pages/auth/AccountTypes";
import LoginPage from "./pages/auth/LoginPage";
import Otp from "./pages/auth/Otp";
import Register from "./pages/auth/Register";
import VerifyPasswordPage from "./pages/auth/VerifyPasswordPage";
import ConfirmEmail from "./pages/auth/ConfirmEmail";
import PrivateLayout from "./layouts/PrivateLayout";
import Home from "./pages/private/Home";
import Historique from "./pages/private/Historique";
import Notifications from "./pages/private/Notifications";
import Profile from "./pages/private/Profile";
import Scan from "./pages/private/Scan";
import ScanResult from "./pages/private/ScanResult";
import ComptesBancaires from "./pages/private/CompteBancaires";
import Loader from "./components/utils/Loader";
import Card from "./pages/private/Card";
import Information from "./pages/private/Information";
import Settings from "./pages/private/Setting";
import Cug from "./pages/private/Cug";
import Support from "./pages/private/Support";
import SupportDiscussion from "./pages/private/SupportDiscussion";
import EditProfil from "./pages/private/EditProfil";
// import {
//   Employe,
//   Employes,
//   ListeSalaires,
//   Options,
//   Verssement,
//   Verssements,
// } from "./pages/salaires";
import AdvancedLayout from "./layouts/AdvancedLayout";
import Tontine from "./pages/tontine/Tontine";
import TontinesView from "./pages/tontine/TontinesView";
import Employees from "./pages/employees/Employees";
import Employee from "./pages/employees/Employee";
import UplaodDocuments from "./pages/auth/UplaodDocuments";
// import Employees from "./pages/employees/employees";
// import Employee from "./pages/employees/Employee";

function App() {
  return (
    <main className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AuthLayout />}>
            <Route path="" element={<AccountTypes />}></Route>
            <Route path="login" element={<LoginPage />}></Route>
            <Route path="otp" element={<Otp />}></Route>
            <Route path="register" element={<Register />}></Route>
            <Route path="password" element={<VerifyPasswordPage />}></Route>
            <Route path="confirmmail" element={<ConfirmEmail />}></Route>
            <Route path="documents" element={<UplaodDocuments />}></Route>
          </Route>

          <Route path="dashboard" element={<PrivateLayout />}>
            <Route path="home" element={<Home />}></Route>
            <Route path="historique" element={<Historique />}></Route>
            <Route path="scan" element={<Scan />}></Route>
            <Route path="result" element={<ScanResult />}></Route>
            <Route path="notifications" element={<Notifications />}></Route>
            <Route path="profile" element={<Profile />}></Route>
            <Route path="cb" element={<ComptesBancaires />}></Route>
            <Route path="card" element={<Card />}></Route>
            <Route path="infos" element={<Information />}></Route>
            <Route path="settings" element={<Settings />}></Route>
            <Route path="cug" element={<Cug />}></Route>
            <Route path="support" element={<Support />}></Route>
            <Route path="support/:id" element={<SupportDiscussion />}></Route>
            <Route path="discussion/:id" element={<SupportDiscussion />}></Route>
            <Route path="editprofil" element={<EditProfil />}></Route>
          </Route>

          <Route path="advanced" element={<AdvancedLayout />}>
            {/* salary layout */}
            {/* <Route path="salaires">
              <Route path="employes" element={<Employes />} />
              <Route path="employe" element={<Employe />} />
              <Route path="options" element={<Options />} />
              <Route path="versements" element={<Verssements />} />
              <Route path="versement" element={<Verssement />} />
              <Route path="liste-salaires" element={<ListeSalaires />} />
            </Route> */}
            {/* Totine routes */}
            <Route path="tontines">
              <Route path="lists" element={<TontinesView />} />
              <Route path="details/:id" element={<Tontine />} />
            </Route>
            {/* Employees routes */}
            <Route path="salary">
              <Route path="employees" element={<Employees />} />
              <Route path="employee/:id" element={<Employee />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;
