import { useReducer } from "react";
import { useBankAccount } from "../store";
import { client, usBankAccount } from "braintree-web";

type IProps = { dismiss: (data?: {id: string, verified: boolean}) => Promise<void> }

export default function BankAccountForm({ dismiss}: IProps) {

    const [state, dispatch] = useReducer(
        (state: any, action: Partial<any>): any => ({
          ...state,
          ...action,
        }),
        {
          ownershipType: "personal",
          accountType: "checking"
        }
      );
      const { getToken, saveNounce } = useBankAccount();
      
      const handleSubmit = async () => {
        console.log(state);
         // generate token
    const token = await getToken();
    // console.log(token);
    // send request
    client
      .create({
        authorization: token,
      })
      .then((clientInstance: any) => {
        console.log(clientInstance);
        return usBankAccount.create(
          { client: clientInstance },
          (res, usBankAccountInstance) => {
            console.log(usBankAccountInstance);
            const bankDetails: any = {
              accountNumber: state.accountNumber,
              routingNumber: state.routingNumber,
              accountType: state.accountType,
              ownershipType: state.ownershipType,
              billingAddress: {
                streetAddress: state.billing_street_address,
                extendedAddress: state.billing_extended_address,
                locality: state.billing_locality,
                region: state.billing_region,
                postalCode: state.billing_postal_code,
              },
            };
            if (state.ownershipType === "personal") {
              bankDetails.firstName = state.firstName;
              bankDetails.lastName = state.lastName;
            } else {
              bankDetails.businessName = state.businessName;
            }
            console.log(bankDetails);
            usBankAccountInstance?.tokenize(
              {
                bankDetails,
                mandateText:
                  'By clicking ["Continuer"], I authorize Braintree, a service of PayPal, on behalf of NamDje (i) to verify my bank account information using bank information and consumer reports and (ii) to debit my bank account.',
                  bankLogin: false
              },
              async (tokenizeErr: any, tokenizedPayload: any) => {
                if (tokenizeErr) {
                  console.error(
                    "There was an error tokenizing the bank details."
                  );
                  throw tokenizeErr;
                }
                console.log(tokenizedPayload);
                const res = await saveNounce(tokenizedPayload.nonce, tokenizedPayload.description)
                console.log(res);
                if (res) {
                  dismiss(res)
                }
              }
            );
          }
        );

        //
      });
      }
    
    return (
        <div
          className="absolute w-full h-screen bg-black bg-opacity-70  z-40 flex flex-col justify-end"
          onClick={() => dismiss()}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto bg-white bg-opacity-100 w-full rounded-t-2xl py-8 space-y-3 p-8 z-50"
          >
            <div className="form-group">
              <label className="text-sm" htmlFor="routing-number">
                Routing Number
              </label>
              <input
                type="number"
                className="w-full text-black outline-none rounded-l-full rounded-r-full px-8 py-1 text-sm  border border-primary"
                id="routing-number"
                name="routing-number"
                placeholder="Routing Number"
                onChange={($event) =>
                  dispatch({ routingNumber: $event.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label className="text-sm" htmlFor="account-number">
                Account Number
              </label>
              <input
                type="number"
                className="w-full text-black outline-none rounded-l-full rounded-r-full px-8 py-1 text-sm  border border-primary"
                id="account-number"
                name="account-number"
                placeholder="Account Number"
                onChange={($event) =>
                  dispatch({ accountNumber: $event.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label className="text-sm" htmlFor="account-type">
                Account Type
              </label>
              <select
                className="w-full text-black outline-none rounded-l-full rounded-r-full px-8 py-1 text-sm  border border-primary"
                id="account-type"
                name="account-type"
                onChange={($event) =>
                  dispatch({ accountType: $event.target.value })
                }
              >
                <option value="checking">
                  Checking
                </option>
                <option value="savings">Savings</option>
              </select>
            </div>
            <div className="form-group">
              <label className="text-sm" htmlFor="ownership-type">
                Ownership Type
              </label>
              <select
                className="w-full text-black outline-none rounded-l-full rounded-r-full px-8 py-1 text-sm  border border-primary"
                id="ownership-type"
                name="ownership-type"
                onChange={($event) =>
                  dispatch({ ownershipType: $event.target.value })
                }
              >
                <option className="w-full" value="personal">
                  Personal
                </option>
                <option className="w-full" value="business">
                  Business
                </option>
              </select>
            </div>
            {state.ownershipType === "personal" ? (
              <>
                <div className="form-group">
                  <label className="text-sm" htmlFor="first-name">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="w-full text-black outline-none rounded-l-full rounded-r-full px-8 py-1 text-sm  border border-primary"
                    id="first-name"
                    name="first-name"
                    placeholder="First Name"
                    onChange={($event) =>
                      dispatch({ firstName: $event.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="text-sm" htmlFor="last-name">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="w-full text-black outline-none rounded-l-full rounded-r-full px-8 py-1 text-sm  border border-primary"
                    id="last-name"
                    name="last-name"
                    placeholder="Last Name"
                    onChange={($event) =>
                      dispatch({ lastName: $event.target.value })
                    }
                  />
                </div>
              </>
            ) : (
              <div className="form-group" id="business-name-div">
                <label className="text-sm" htmlFor="business-name">
                  Business Name
                </label>
                <input
                  type="text"
                  className="w-full text-black outline-none rounded-l-full rounded-r-full px-8 py-1 text-sm  border border-primary"
                  id="business-name"
                  name="business-name"
                  placeholder="Business Name"
                  onChange={($event) =>
                    dispatch({ businessName: $event.target.value })
                  }
                />
              </div>
            )}

            <div className="form-group">
              <label className="text-sm" htmlFor="billingAddress">
                Billing Address
              </label>
              <div className="w-full flex gap-2 mb-2">
                <div className="w-1/2">
                  <input
                    type="text"
                    className="w-full flex gap-2 mb-2 text-black outline-none rounded-l-full rounded-r-full px-8 py-1 text-sm  border border-primary"
                    id="billing-street-address"
                    name="billing-street-address"
                    placeholder="Street Address"
                    onChange={($event) =>
                      dispatch({ billing_street_address: $event.target.value })
                    }
                  />
                </div>
                <div className="w-1/2">
                  <input
                    type="text"
                    className="w-full flex gap-2 mb-2 text-black outline-none rounded-l-full rounded-r-full px-8 py-1 text-sm  border border-primary"
                    id="billing-extended-address"
                    name="billing-extended-address"
                    placeholder="Extended Address"
                    onChange={($event) =>
                      dispatch({
                        billing_extended_address: $event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="w-full flex gap-2 mb-2">
                <div className="w-1/2">
                  <input
                    type="text"
                    className="w-full flex gap-2 mb-2 text-black outline-none rounded-l-full rounded-r-full px-8 py-1 text-sm  border border-primary"
                    id="billing-locality"
                    name="billing-locality"
                    placeholder="City"
                    onChange={($event) =>
                      dispatch({ billing_locality: $event.target.value })
                    }
                  />
                </div>
                <div className="w-1/2">
                  <input
                    type="text"
                    className="w-full flex gap-2 mb-2 text-black outline-none rounded-l-full rounded-r-full px-8 py-1 text-sm  border border-primary"
                    id="billing-region"
                    name="billing-region"
                    placeholder="State"
                    onChange={($event) =>
                      dispatch({ billing_region: $event.target.value })
                    }
                  />
                </div>
              </div>
              <div className="w-full flex gap-2 mb-2">
                <div className="w-1/2">
                  <input
                    type="text"
                    className="w-full flex gap-2 mb-2 text-black outline-none rounded-l-full rounded-r-full px-8 py-1 text-sm  border border-primary"
                    id="billing-postal-code"
                    name="billing-postal-code"
                    placeholder="Zip Code"
                    onChange={($event) =>
                      dispatch({ billing_postal_code: $event.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="form-group form-check">
              <input
                type="checkbox"
                className="form-check-input mr-4"
                id="mandateCheckbox"
                name="mandateCheckbox"
                onChange={($event) =>
                  dispatch({ mandateCheckbox: $event.target.checked })
                }
              />
              <label className="text-sm" htmlFor="mandateCheckbox">
                I agree to the mandate text.
              </label>
              <p className="form-text text-gray-500 mt-4 text-sm">
                By clicking "Continuer", I authorize Braintree, a service of
                PayPal, on behalf of NamDje (i) to verify my bank account
                information using bank information and consumer reports and (ii)
                to debit my bank account.
              </p>
            </div>

            <div className="pt-4">
              <button
                disabled={!state.mandateCheckbox}
                className="w-full bg-secondary py-1 rounded-l-full rounded-r-full"
                onClick={handleSubmit}
              >
                {" "}
                Continuer{" "}
              </button>
            </div>
          </div>
        </div>
    );
}