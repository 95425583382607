// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getAuth } from "firebase/auth"
import { getMessaging } from "firebase/messaging"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBDYo5ptmUtOtgtizEnyeo82a8E3LfF-IA",
    authDomain: "namdje-auth.firebaseapp.com",
    projectId: "namdje-auth",
    storageBucket: "namdje-auth.appspot.com",
    messagingSenderId: "492189781306",
    appId: "1:492189781306:web:7afef09ba9adbcb5219403",
    measurementId: "G-E9EK2SJCB3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)
const auth = getAuth(app)
const messaging = getMessaging(app)

export {
    // analytics,
    auth,
    messaging,
}
