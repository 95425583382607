import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { MouseEventHandler, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
type IDropDown = {
  title: string;
  path: string | null;
  subtitles:
    | {
        title: string;
        path: string;
      }[]
    | null;
};

const DropDown = ({ path, subtitles, title }: IDropDown) => {
  const [isDrop, setIsDrop] = useState<boolean>(() => true);
  const dropHandler: MouseEventHandler<HTMLButtonElement> = () =>
    setIsDrop((prev) => !prev);

  return (
    <ul className="w-full space-y-2">
      {!subtitles ? (
        <li className="py-1 text-lg text-primary">
          <Link to={path ?? ""}>{title}</Link>
        </li>
      ) : (
        <li className="w-full">
          <button
            onClick={dropHandler}
            className="w-full text-base flex  items-center justify-between py-1"
          >
            {title}
            {isDrop ? (
              <ChevronDownIcon className="w-4" />
            ) : (
              <ChevronRightIcon className="w-4" />
            )}
          </button>
          <ul
            className={
              isDrop ? "m-2 space-y-1 bg-gray-50 px-2 rounded" : "hidden"
            }
          >
            {subtitles?.map(({ path, title }, idx) => (
              <li key={idx} className="py-1">
                <Link to={path ?? ""}>{title}</Link>
              </li>
            ))}
          </ul>
        </li>
      )}
    </ul>
  );
};

export default DropDown;
