import React from 'react'
import { AccountType } from '../models';
type props={
  Icon:any;
  title:string;
  onSelectAccountType:()=>void
}

const AccoutTypeItem = ({Icon,title,onSelectAccountType}:props) => {
  return (
    <div onClick={onSelectAccountType} className='w-full h-full max-w-lg mx-auto bg-white shadow-lg border border-primary rounded-xl flex flex-col justify-between p-8 md:cursor-pointer md:justify-center  md:shadow-2xl ' >
        <Icon
        className='h-12 md:h-32 text-primary'
        />
        <p className='md:font-bold  md:text-[1.5em]'>{title}</p>
    </div>
  )
}

export default AccoutTypeItem