import {
  ArrowLeftOnRectangleIcon,
  BuildingLibraryIcon,
  ChatBubbleLeftRightIcon,
  ChevronRightIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  DocumentTextIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../store";
import { useNavigate } from "react-router-dom";
import { AccountType } from "../../models";
import LogoImg from "../../assets/img/logo.png";

const Profile = () => {
  const { getProfile, logout } = useAuth();
  const [profile, setProfile] = useState<any>();
  const [accountType] = useState<AccountType>(
    window.sessionStorage.getItem("nj_accountType") as AccountType
  );

  const navigate = useNavigate();

  useEffect(() => {
    const localProfile = getProfile();
    console.log(profile);
    localProfile ? setProfile(localProfile) : navigate("/");
  }, []);

  const onLogout = async () => {
    await logout(accountType);
    navigate("/");
  };

  const goToInfos = () => navigate("/dashboard/infos");
  const goToSettings = () => navigate("/dashboard/settings");
  const goToCug = () => navigate("/dashboard/cug");
  const goToCard = () => navigate("/dashboard/card");
  const goToComptesBancaires = () => navigate("/dashboard/cb");
  // const goToSupport = () => navigate("/dashboard/support");

  return (
    <div className="h-full w-full">
      <div
        className={`${
          accountType === AccountType.CLIENT
            ? "bg-secondary text-white"
            : accountType === AccountType.kIOSQUE
            ? "bg-teal-300 text-dark"
            : "bg-tertiary text-white"
        } h-48 rounded-b-2xl w-full flex-col justify-between`}
      >
        <div className="w-full h-12 px-8  flex justify-between items-center">
          <h3 className="text-center text-lg font-bold">Mon Profile</h3>
          {/* <button className="rounded-xl w-8 h-8 flex justify-center items-center  aspect-square">
            <ArrowLeftOnRectangleIcon className="w-[1.4rem] text-light" />
          </button> */}
        </div>
        <div className="w-full flex gap-4 p-2">
          <div className="w-24 h-24 bg-gray-500 rounded-full">
            <img
              src={profile?.avatar || LogoImg}
              alt=""
              className="w-full h-full rounded-full"
            />
          </div>
          <div className="flex flex-col py-4 justify-between">
            <h2 className="font-bold">{profile?.name}</h2>
            <h3>{profile?.pseudo}</h3>
            <h3 className="text-md">
              Compte{" "}
              {accountType === AccountType.CLIENT
                ? "Client"
                : accountType === AccountType.kIOSQUE
                ? "Kiosque"
                : "Marchand"}
            </h3>
          </div>
        </div>
      </div>

      <div className="space-y-4 p-8">
        <div
          onClick={goToInfos}
          className="w-full flex justify-between items-center gap-4 space-y-4 text-primary"
        >
          <div className="flex gap-4 items-center">
            <UserIcon className="w-4 h-4 " />
            <h3> Infos du compte</h3>
          </div>
          <ChevronRightIcon className="w-4 h-4" />
        </div>

        <div
          onClick={goToSettings}
          className="w-full flex justify-between items-center gap-4 space-y-4 text-primary"
        >
          <div className="flex gap-4 items-center">
            <Cog6ToothIcon className="w-4 h-4 " />
            <h3> Paramètres et règlages</h3>
          </div>
          <ChevronRightIcon className="w-4 h-4" />
        </div>

        <div
          onClick={goToCug}
          className="w-full flex justify-between items-center gap-4 space-y-4 text-primary"
        >
          <div className="flex gap-4 items-center">
            <DocumentTextIcon className="w-4 h-4 " />
            <h3> Termes et utlisations</h3>
          </div>
          <ChevronRightIcon className="w-4 h-4" />
        </div>
        {accountType !== AccountType.kIOSQUE ? (
          <div
            onClick={goToCard}
            className="w-full flex justify-between items-center gap-4 space-y-4 text-primary "
          >
            <div className="flex gap-4 items-center">
              <CreditCardIcon className="w-4 h-4 ≈" />
              <h3> Ma carte numérique</h3>
            </div>
            <ChevronRightIcon className="w-4 h-4" />
          </div>
        ) : (
          <></>
        )}

        <div
          onClick={goToComptesBancaires}
          className="w-full flex justify-between items-center gap-4 space-y-4 text-primary"
        >
          <div className="flex gap-4 items-center">
            <BuildingLibraryIcon className="w-4 h-4 ≈" />
            <h3> Mes comptes bancaires</h3>
          </div>
          <ChevronRightIcon className="w-4 h-4" />
        </div>
        {/* <div
          onClick={goToSupport}
          className="w-full flex justify-between items-center gap-4 space-y-4 text-primary"
        >
          <div className="flex gap-4 items-center">
            <ChatBubbleLeftRightIcon className="w-4 h-4 ≈" />
            <h3> Support technique</h3>
          </div>
          <ChevronRightIcon className="w-4 h-4" />
        </div> */}

        <div
          onClick={onLogout}
          className="w-full flex justify-between items-center gap-4 space-y-4 text-red-500"
        >
          <div className="flex gap-4 items-center ">
            <ArrowLeftOnRectangleIcon className="w-4 h-4 " />
            <h3> Se déconnecter</h3>
          </div>
          <ChevronRightIcon className="w-4 h-4" />
        </div>
      </div>
    </div>
  );
};

export default Profile;
