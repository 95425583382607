import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoaderImg from "../../assets/img/loader.gif";
import { AccountType, DocumentType } from "../../models";
import { useAuth } from "../../store";

const UplaodDocuments = () => {
  const navigate = useNavigate();

  const [documents, setDocuments] = useState<any[]>([]);
  const [document, setDocument] = useState<File>();
  const [activeDocument, setActiveDocument] = useState<DocumentType>(
    DocumentType.SELFIE
  );
  const [accountType] = useState<AccountType>(
    window.sessionStorage.getItem("nj_accountType") as AccountType
  );
  const [phone, setPhone] = useState<string>(
    window.sessionStorage.getItem("nj_userId") as string
  );
  const [loader, setLoader] = useState(false);
  const { register, uploadSignupDocument } = useAuth();

  const uploadHandle = async (type: DocumentType) => {
    console.log("uploads files", type);
    if (!document) return window.alert("Veuillez choisir un fichier!");
    setLoader(true);
    console.log({ name: type, document });
    const data = await uploadSignupDocument({
      name: type,
      account_type: accountType,
      phone: phone,
      document,
    });
    setDocument(undefined);
    console.log(data);
    if (!data) {
      setLoader(false);
      return window.alert("Une erreur s'est produite!");
    }
    const docIndex = documents.findIndex((doc) => doc === type);

    if (docIndex < documents.length - 1)
      setActiveDocument(documents[docIndex + 1]);
    else {
      window.alert(
        "Vos documents sont encours de validation. Cette opération durera moin de 48 heures."
      );
      navigate("/login");
    }
    setLoader(false);
  };
  const skipDocument = async () => {
    navigate("/dashboard/home");
  };
  return (
    <>
      {loader ? (
        <div
          className="absolute  top-0 left-0 right-0 bottom-0 w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setLoader(false);
          }}
        >
          <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
            {/* <h2 className="text-lg uppercase font-bold">
            Entrer le montant <br />
          </h2> */}

            <img src={LoaderImg} alt="" />
            <p className="text-gray-500 italic text-sm">Connexion...</p>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className=" h-full flex pt-3 flex-col items-center justify-between">
        <h2 className="text-3xl text-light">Documents</h2>
        <div className="w-full px-4 h-full flex flex-col items-center justify-around  ">
          {activeDocument === DocumentType.SELFIE ? (
            <>
              {" "}
              <h3 className="text-xl text-tertiary mb-2">
                Ajouter une photo d'identité
              </h3>
              <div>
                <input
                  type="file"
                  onChange={($event: any) =>
                    setDocument($event.target.files[0])
                  }
                  className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  "
                />
              </div>
              <div className="w-full flex justify-between gap-4">
                <button
                  className="bg-light border border-red-600 text-red-600 px-4 py-2 rounded-l-full rounded-r-full w-full "
                  onClick={skipDocument}
                >
                  Passer
                </button>
                <button
                  className="bg-secondary px-4 py-2   rounded-l-full rounded-r-full text-dark w-full"
                  onClick={() => uploadHandle(DocumentType.SELFIE)}
                >
                  Enregistrer
                </button>
              </div>
            </>
          ) : activeDocument === DocumentType.ID ? (
            <>
              <h3 className="text-xl text-tertiary mb-2">
                Ajouter votre carte d'identité
              </h3>
              <input
                type="file"
                onChange={($event: any) => setDocument($event.target.files[0])}
                placeholder="Ajouter votre carte d'identité"
                className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  "
              />
              <button
                className="bg-secondary px-4 py-2   rounded-l-full rounded-r-full text-dark"
                onClick={() => uploadHandle(DocumentType.ID)}
              >
                Enregistrer
              </button>
            </>
          ) : activeDocument === DocumentType.BUSINESS_CARD ? (
            <>
              <h3 className="text-xl text-tertiary mb-2">
                Ajouter votre carte d'entrepreneur
              </h3>
              <input
                type="file"
                onChange={($event: any) => setDocument($event.target.files[0])}
                placeholder="Ajouter votre carte d'identité"
                className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  "
              />
              <button
                className="bg-secondary px-4 py-2   rounded-l-full rounded-r-full text-dark"
                onClick={() => uploadHandle(DocumentType.BUSINESS_CARD)}
              >
                Enregistrer
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default UplaodDocuments;
