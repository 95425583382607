import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

type IProps = { amount: string, currencyCode: string, cb: (orderId: string) => void}

export default function PayPalBtns({ amount, currencyCode, cb}: IProps) {
    return (
        <PayPalScriptProvider options={{ clientId: "AcDIcaTfDJCBjbiBdP3mQczOoQ70r2vSNr6RS_TiBGaID34cPpYN74-x-OFumVRPgo_bQXgNMuwFxtnX" }}>
            <PayPalButtons
            style={{ color:  'blue',
            shape:  'pill',
            label:  'pay',
            height: 40 }}
                createOrder={(data, actions: any) => {
                    console.log(amount, currencyCode);
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: amount,
                                    currency_code: currencyCode
                                },
                            },
                        ],
                    });
                }}
                onApprove={(data, actions: any) => {
                    return actions.order.capture().then((details: any) => {
                        const name = details.payer.name.given_name;
                        // alert(`Transaction completed by ${name}`);
                        cb(data.orderID)
                    });
                }}
            />
        </PayPalScriptProvider>
    );
}