import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useEmployement } from "../../store";
import LogoImg from "../../assets/img/logo.png";
import {
  ArrowLeftOnRectangleIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import Modal from "../../components/utils/Modal";

const Employee = () => {
  const params = useParams();
  const { getEmployee, deleteEmployee, updateEmployer } = useEmployement();
  const [employee, setEmployee] = useState<any>();
  const [modal, setModal] = useState<"salary" | "password" | "response" | null>(
    null
  );
  const [salary, setSalary] = useState<number | null>(null);
  const [password, setPassword] = useState("");
  const [response, setResponse] = useState<any>();

  useEffect(() => {
    const id = params.id;
    console.log(id);
    if (id) loadEmployee(id);
  }, []);

  const loadEmployee = async (id: string) => {
    const { data } = await getEmployee(id);
    console.log(data);
    setEmployee(data);
  };

  const handleDelete = async () => {
    const res = await deleteEmployee(employee.id);
    console.log(res);
  };

  const handleUpdate = async () => {};

  return (
    <>
      <div className="h-full w-full space-y-8 bg-slate-50">
        <div className="w-full  space-y-4  bg-secondary p-4 text-white">
          <div className="w-full flex justify-center">
            <img
              src={employee?.avatar || LogoImg}
              className="w-32 h-32 rounded-full"
              alt=""
            />
          </div>

          <div className="w-full text-center">
            <h3 className="text-lg font-bold ">
              {employee?.name || "AMADOU Arouna"}
            </h3>
            <span>{employee?.phone || "+22892942601"}</span>
          </div>
        </div>
        <div className="shadow rounded bg-white h-20 mx-8 flex items-center justify-center">
          <h2 className="text-4xl font-bold text-primary">
            {employee?.salary || "250 000"} F
          </h2>
        </div>

        <div className="flex justify-between px-8">
          <button
            onClick={() => setModal("salary")}
            className="px-2 py-1 bg-tertiary rounded text-lg"
          >
            Modifier le salaire
          </button>

          <button
            onClick={handleDelete}
            className="px-2 py-1 text-lg bg-red-600 text-white rounded"
          >
            Retirer le compte
          </button>
        </div>
      </div>

      {modal === "salary" && (
        <Modal>
          <div className="w-full text-left">
            <button
              onClick={() => setModal(null)}
              className="p-2 font-bold text-red-500"
            >
              Annuler
            </button>
            <div className="w-full p-4 space-y-4">
              <input
                onChange={(e) => setSalary(+e.target.value)}
                name="salary"
                type="number"
                placeholder=""
                value={employee?.salary || 250000}
                className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary p-6 rounded h-10 text-primary text-lg "
              />
              <div className="flex justify-center w-full px-2 pt-4">
                <button
                  onClick={() => setModal('password')}
                  className="bg-secondary px-4 py-2 rounded text-white w-full"
                  type="submit"
                >
                  Continuer
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {modal === "password" && (
        <Modal>
          <div className="w-full text-left">
            <button
              onClick={() => setModal(null)}
              className="p-2 font-bold text-red-500"
            >
              Annuler
            </button>
            <div className="w-full p-4 space-y-4">
              <input
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                type="password"
                placeholder="Votre mot de passe"
                className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary p-6 rounded h-10 text-primary text-lg "
              />
              <div className="flex justify-center w-full px-2 pt-4">
                <button
                  onClick={handleUpdate}
                  className="bg-secondary px-4 py-2 rounded text-white w-full"
                  type="submit"
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {modal === "response" && (
        <Modal>
          <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
            {response.success ? (
              <>
                {" "}
                <CheckCircleIcon className="w-32 h-32 text-secondary" />
                <p className="text-secondary italic text-sm">
                  Votre tontine a été créée avec succès. Vous pouvez maintenant
                  inviter des membres
                </p>
              </>
            ) : (
              <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
                {" "}
                <InformationCircleIcon className="w-32 h-32 text-red-500" />
                <p className="text-red-500 italic text-sm">
                  {response?.data?.message || "Une erreur s' est produite!"}
                </p>
                {/* <button className="bg-tertiary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark">
               Terminer
             </button> */}
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

export default Employee;
