import { AccountType } from "../models";
import { useApi } from "./api";

export const useContact = () => {
  const { $get, $post, objToArray } = useApi();

  const getContacts = async (account_type?: AccountType) => {
    const { data } = await $get("account/contact/get_contacts/");
    if (data) {
      // console.log(data);
      // const result = Object.keys(data).map((key) => ({
      //   id: key,
      //   phone: data[key].id,
      //   name: data[key].name,
      //   avatar: data[key].icon,
      // }));
      return data;
    }
    return [];
  };

  const saveContact = async (receiver_id: string) => {
    try {
      const res = await $post(
        "account/contact/create_contact_from_receiver_id/",
        { receiver_id },
        false
      );
      // console.log(res);
      return;
    } catch (err) {
      window.alert("Echec d'enregistrement du contact!");
    }
  };

  return { getContacts, saveContact };
};
