import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import DropDown from "../DropDown";
import { asideBarMenuList } from "../../constant";
import { AccountType, IProfile } from "../../models";
import LogoImg from "../../assets/img/logo.png";
import { useAuth } from "../../store";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ children, handleClick }: any) => {
  const { getProfile, logout } = useAuth();
  const navigate = useNavigate();
  const [accountType] = useState<AccountType>(
    window.sessionStorage.getItem("nj_accountType") as AccountType
  );
  const [profile, setProfile] = useState<IProfile>();

  const [discussions, setDiscussions] = useState<any[]>([
    {
      id: "1",
      title: "Support",
      messages: [
        {
          id: 1,
          content: "Message 1",
          date: "2022-10-10",
          sender: "Support",
        },

        {
          id: 2,
          content: "Message 2",
          date: "2022-10-10",
          sender: "Aroo",
        },

        {
          id: 3,
          content: "Message 3",
          date: "2022-10-10",
          sender: "Support",
        },
      ],
    },
    {
      id: "2",
      title: "Tontine A",
      messages: [
        {
          id: 1,
          content: "Message 1",
          date: "2022-10-10",
          sender: "Aroo",
        },

        {
          id: 2,
          content: "Message 2",
          date: "2022-10-10",
          sender: "Member1",
        },

        {
          id: 3,
          content: "Message 3",
          date: "2022-10-10",
          sender: "Member2",
        },
      ],
    },
  ]);

  const goToDiscussion = (id: string) => {
    navigate(`/dashboard/discussion/${id}`);
  }

  useEffect(() => {
    const localProfile = getProfile();
    setProfile(localProfile);
  }, []);

  const onLogout = async () => {
    await logout(accountType);
    navigate("/");
  };

  return (
    <aside
      className="absolute h-screen bg-white z-50 min-w-[16rem] text-sm p-2 flex flex-col justify-between"
      onClick={handleClick}
    >
      <section className="w-full divide-y-2 ">
        <div className="w-full flex items-center gap-4 pb-2">
          {/* <UserIcon className="w-24 h-24 rounded-full p-2 border" /> */}
          <div className="w-24 h-24 bg-gray-500 rounded-full">
            <img
              src={profile?.avatar || LogoImg}
              alt=""
              className="w-full h-full rounded-full"
            />
          </div>
          <div className="py-2">
            <h3 className="text-secondary">{profile?.name}</h3>
            <p>
              Compte{" "}
              {accountType === AccountType.CLIENT
                ? "Client"
                : accountType === AccountType.BUSINESS
                ? "Marchand"
                : accountType === AccountType.kIOSQUE
                ? "Kiosque"
                : ""}
            </p>
          </div>
        </div>
        <div className="w-full space-y-3 py-8 divide-y">
          {asideBarMenuList
            .filter((item) => item.roles.includes(accountType))
            .map((lists, idx) => (
              <DropDown key={idx} {...lists} />
            ))}
        </div>
      </section>

      {/* Discussions menu */}
     
      <div className="py-8 flex justify-center">
        <button
          onClick={onLogout}
          className="bg-tertiary text-sm px-5 py-3 rounded text-white"
        >
          Se déconnecter
        </button>
      </div>
    </aside>
  );
};

export default Sidebar;
