import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import { asideBarMenuList } from "../constant";

interface Props {
  // Define your component's props here
}

const AdvancedLayout: React.FC<Props> = (props) => {
  // get current path
  const path = window.location.pathname;
//   console.log(path);
  const menu =asideBarMenuList.find((item) => item.subtitles?.find((sub) => sub.path === path))?.subtitles?.find((item) => item.path === path);
  const title = menu?.title
//   console.log(title);
  return (
    <div className="h-screen">
      {/* Render your component's content here */}
      {/* header section with return button */}
      <div className="flex gap-4 shadow-md font-ligh items-center px-4">
        <Link to={"/dashboard/home"}>
          <ArrowLeftIcon className="w-6 h-6 " />
        </Link>
        <h2 className="w-full p text-lg pl-4 py-4 t">{title}</h2>
      </div>
      <Outlet />
    </div>
  );
};

export default AdvancedLayout;
