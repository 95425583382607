import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ITontine } from "../../models";
import { useTontine } from "../../store";
import Modal from "../../components/utils/Modal";
import { Draggable } from "react-drag-reorder";
import { CheckIcon, LockClosedIcon } from "@heroicons/react/20/solid";

const Tontine = () => {
  const params = useParams();
  const {
    getTontine,
    getMembers,
    approveMember,
    approveAllMembers,
    rejectMember,
    rejectAllMembers,
    changeMemberOrder,
    startTonine,
  } = useTontine();
  const [tontine, setTontine] = useState<ITontine>();
  const [members, setMembers] = useState<any[]>([]);
  const [password, setPassword] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [modal, setModal] = useState<"password" | "start_date" | null>(null);
  const [actions, setActions] = useState("");
  const [actionId, setActionId] = useState("");
  const [reload, setReload] = useState(new Date().getTime().toString());

  useEffect(() => {
    const id = params["id"];
    if (!id) return;
    const load = async () => {
      const tontine: ITontine = await getTontine(id);
      const members = await getMembers(id);
      setTontine(tontine);
      setMembers(members);
      console.log(tontine, members);
    };
    load();
  }, [reload]);

  const startAction = (actions: string) => {
    setActions(actions);
    setModal("password");
  };

  const confirmAction = async () => {
    switch (actions) {
      case "approve":
        await approveMember({
          member_id: actionId,
          password,
          tontine_id: tontine?.id,
          status: "active",
        });
        break;
      case "reject":
        await rejectMember({
          member_id: actionId,
          password,
          tontine_id: tontine?.id,
          status: "active",
        });
        break;
      case "approveAll":
        await approveAllMembers({
          tontine_id: tontine?.id,
          password,
          status: "active",
        });
        break;

      case "rejectAll":
        await rejectAllMembers({
          tontine_id: tontine?.id,
          password,
          status: "rejected",
        });
        break;
      case "start":
        await startTonine({
          tontine_id: tontine?.id || "",
          password,
          start_date: startDate.toString(),
        });
        break;
      default:
        break;
    }

    setModal(null);
    setReload(new Date().getTime().toString());
  };

  const getChangedPos = (currentPos: number, newPos: number) => {
    console.log(currentPos, newPos);
    const member = members[currentPos];
    changeMemberOrder({
      tontine_id: tontine?.id || "",
      member_round: newPos,
      member_id: member.id,
      password,
    });
  };


  const onSetSelectStartDate = () => {
    startAction("start")
    setModal("start_date");
  }

  return (
    <div className="p-4 space-y-4 overflow-y-hidden">
      <h1 className="font-bold flex justify-between">
        Tontine {tontine?.title}
        <div className="text-sm">
          {tontine?.status === "active" ? (
            <span className="p-1 bg-green-500 text-white px-2 py-1 rounded-full">
              En cours
            </span>
          ) : tontine?.status === "closed" ? (
            <span className="p-1 bg-primary text-white px-2 py-1 rounded-full">
              En attente
            </span>
          ) : tontine?.status === "pending" ? (
            <span className="p-1 bg-tertiary text-white px-2 py-1 rounded-full">
              En attente
            </span>
          ) : (
            <></>
          )}
        </div>
      </h1>

      <div className=" w-full flex justify-center">
        <img src={tontine?.qr} alt="" className="w-32 h-32" />
      </div>

      <div>
        <p className="text-medium font-extralight">{tontine?.description}</p>
      </div>

      <div className="w-full grid grid-cols-2 divide-x border p-2">
        <div className="text-center font-bold">
          Solde:{" "}
          <span className="text-primary">{tontine?.cash_balance} F CFA</span>
        </div>

        <div className="text-center text-primary">
          {tontine?.amount} F CFA /
          {tontine?.frequency === "monthly" ? "mois" : "semaine"}
        </div>
      </div>

      <div className="py-8">
        <div className="flex justify-between items-center">
          <h2 className="font-bold text-lg">
            Membres{" "}
            <span className="bg-secondary rounded-full  px-2">
              {members?.length} /{tontine?.number_of_people}
            </span>
          </h2>

          {tontine?.status === "pending" ? (
            <button
              onClick={onSetSelectStartDate}
              className="bg-primary text-white py-1 px-2 rounded"
            >
              <span>Demarrer</span>
            </button>
          ) : (
            <></>
          )}
        </div>

        <div className=" space-y-4 py-2 divide-y">
          {tontine?.status === "pending" ? (
            <div className="bg-red-50 py-4 px-2 rounded">
              <div className="flex justify-between items-center">
                <h3>Invitation en attente</h3>
                <div className="flex gap-2 items-center">
                  <button
                    onClick={() => startAction("approveAll")}
                    className="border border-green-600 text-green-600 py-1 px-2 rounded"
                  >
                    Approuver
                  </button>
                  <button
                    onClick={() => startAction("rejectAll")}
                    className="border border-red-600 text-red-600 py-1 px-2 rounded"
                  >
                    Rejeter
                  </button>
                </div>
              </div>

              <div className="divide-y">
                {members
                  ?.filter((member) => member.status === "pending")
                  .map((member) => (
                    <div
                      key={member.id}
                      className="flex w-full justify-between items-center bg-white p-1 rounded"
                    >
                      <div className="py-1">
                        <h3 className="font-semibold">{member.name}</h3>
                        <p className="text-sm">{member.phone}</p>
                      </div>
                      <div className=" flex items-center gap-2">
                        {/* Cotisation status */}

                        <button
                          onClick={() => {
                            setActionId(member.id);
                            startAction("approve");
                          }}
                          className="border border-green-600 text-green-600 py-1 px-2 rounded"
                        >
                          <CheckIcon className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => {
                            setActionId(member.id);
                            startAction("reject");
                          }}
                          className="border border-red-600 text-red-600 py-1 px-2 rounded"
                        >
                          {/* Close icone */}
                          <LockClosedIcon className="h-4 w-4" />
                        </button>
                      </div>
                    </div>
                  ))}
                {members?.filter((member) => member.status === "pending")
                  .length === 0 && (
                  <p className="italic text-sm text-red-500 text-center py-4">
                    Vous n'avez pas d'invitation en attente
                  </p>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}

          <div className="bg-blue-50 py-4 px-2 rounded">
            <div className="flex justify-between items-center pb-4">
              <h3 className="">Membres en cours</h3>
              <span className="text-primary">
                {(tontine?.amount || 0) * (members?.length || 0)} F CFA /membre
              </span>
            </div>

            {/* <div className="w-full divide-x border p-2">
              <div className="text-center font-bold">
                Montant à recevoir:{" "}
                <span className="text-primary">
                  {(tontine?.amount || 0) * (members?.length || 0)} F CFA
                </span>
              </div>
            </div> */}
            <div className="divide-y">
              {members.length === 0 ? (
                <p className="italic text-sm text-red-500 text-center">
                  Chargement...
                </p>
              ) : (
                <Draggable onPosChange={getChangedPos}>
                  {members
                    ?.filter(
                      (member) =>
                        !member.received_payment && member.status === "active"
                    )
                    .map((member) => (
                      <div
                        key={member.id}
                        className="flex w-full justify-between items-center bg-white p-1 rounded"
                      >
                        <div className="py-1">
                          <h3 className="font-semibold">{member.name}</h3>
                          <p className="text-sm">{member.phone}</p>
                        </div>
                        <div className=" flex flex-col">
                          {/* Cotisation status */}
                          {tontine?.round ===
                          member.receive_payment_at_round ? (
                            <button
                              disabled={
                                (tontine?.cash_balance as any) >=
                                (members?.length - 1) * (tontine?.amount || 0)
                              }
                              className="border py-1 px-2 rounded"
                            >
                              Virer
                            </button>
                          ) : member.last_round_paid < (tontine?.round || 0) ? (
                            <span className=" text-sm p-1 bg-tertiary text-white px-2 py-1 rounded-full">
                              Non cotisé
                            </span>
                          ) : (
                            <span className=" text-sm p-1 bg-primary text-white px-2 py-1 rounded-full">
                              Cotisé
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                </Draggable>
              )}
            </div>
          </div>
          {tontine?.status !== "pending" ? (
            <div className="bg-green-50 py-4 px-2 rounded">
              <div className="flex justify-between items-center">
                <div>
                  <h3>Membres virés</h3>
                </div>

                <div className="text-center font-bold">
                  <span className="text-primary">
                    {(tontine?.amount || 0) * (members?.length || 0)} F CFA
                  </span>
                </div>
              </div>

              <div className="divide-y">
                {members
                  ?.filter((member) => member.received_payment)
                  .map((member) => (
                    <div
                      key={member.id}
                      className="flex w-full justify-between items-center"
                    >
                      <div className="py-1">
                        <h3 className="font-semibold">{member.name}</h3>
                        <p className="text-sm">{member.phone}</p>
                      </div>
                      <div className="">
                        {/* Cotisation status */}
                        {member.status === "pending" ? (
                          <span className="p-1 bg-tertiary text-white px-2 py-1 rounded-full">
                            En attente
                          </span>
                        ) : member.status === "active" ? (
                          <span className="p-1 bg-green-500 text-white px-2 py-1 rounded-full">
                            Actif
                          </span>
                        ) : (
                          <span className="p-1 bg-primary text-white px-2 py-1 rounded-full">
                            Inactif
                          </span>
                        )}
                        <button>Virer</button>
                      </div>
                    </div>
                  ))}
                {members?.filter((member) => member.received_payment).length ===
                  0 && (
                  <p className="italic text-sm text-red-500 text-center">
                    Aucun membre n'est encore payé
                  </p>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {modal && (
        <Modal>
          {modal === "start_date" ? (
            <div className="p-4 w-full space-y-4">
              <input
                onChange={(e) => setStartDate(new Date(e.target.value))}
                name="start_date"
                type="date"
                placeholder="Date de début"
                className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary p-6 rounded h-10 text-primary text-lg "
              />
              <div className="flex justify-around w-full gap-4 px-2 pt-4">
                <button
                  onClick={() => setModal(null)}
                  className="bg-neutral-400 px-4 py-2 rounded text-white w-full"
                >
                  retour
                </button>
                <button
                  onClick={() => setModal("password")}
                  className="bg-secondary px-4 py-2 rounded text-white w-full"
                  type="submit"
                >
                  Valider
                </button>
              </div>
            </div>
          ) : modal === "password" ? (
            <div className="p-4 space-y-4">
              <input
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                type="password"
                placeholder="Votre mot de passe"
                className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary p-6 rounded h-10 text-primary text-lg "
              />
              <div className="flex justify-around w-full gap-4 px-2 pt-4">
                <button
                  onClick={() => setModal(null)}
                  className="bg-neutral-400 px-4 py-2 rounded text-white w-full"
                >
                  retour
                </button>
                <button
                  onClick={confirmAction}
                  className="bg-secondary px-4 py-2 rounded text-white w-full"
                  type="submit"
                >
                  Valider
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Modal>
      )}
    </div>
  );
};
export default Tontine;
