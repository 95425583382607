import {
  ArrowLeftIcon,
  Cog6ToothIcon,
  CreditCardIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoImg from "../../assets/img/logo.png";
import { useBankAccount } from "../../store";
import { client, usBankAccount } from "braintree-web";
import BankAccountForm from "../../components/BankAcountForm";

const ComptesBancaires = () => {
  const navigate = useNavigate();

  const [accounts, setAccounts] = useState([
    {
      id: "929169975-007929-b8K3iMzmRReA8gV6QTvw9Q",
      date: "2023-06-26",
      time: "23:55:26",
      ownership_type: "personal",
      account_type: "checking",
      bank_name: "FEDERAL RESERVE BANK",
      last_4: "0000",
      account_holder_name: "Mawaki Bidjada",
      verified: "True",
      status: "active",
    },
  ]);

  const [selectedAccount, setSelectedAccount] = useState<any>();
  const [displayDetails, setDisplayDetails] = useState<boolean>(false);
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const { getBankAccounts } = useBankAccount();

  useEffect(() => {
    loadBankAccounts();
  }, []);

  const loadBankAccounts = async () => {
    const accounts = await getBankAccounts();
    console.log(accounts);
    setAccounts(accounts);
  };
  const seeDetails = (id: string) => {
    const account = accounts.find((account) => account.id === id);
    setSelectedAccount(account);
    setDisplayDetails(true);
  };

  const onAdd = () => {
    setDisplayForm(true);
  };

  const onDismissBankAccountForm = async (data?: {
    id: string;
    verified: boolean;
  }) => {
    setDisplayForm(false);
    console.log(data);
    if (!data) {
      return;
    }

    if (!data.verified) {
      return setDisplayForm(false);
    }

    loadBankAccounts();
  };

  const goBack = () => {
    navigate("/dashboard/profile");
  };

  return (
    <>
      {/* Details */}
      {displayDetails ? (
        <div
          className="absolute w-full h-screen bg-black bg-opacity-70  z-40 flex flex-col justify-end"
          onClick={() => setDisplayDetails(false)}
        >
          <div className="mx-auto bg-white bg-opacity-100 w-full rounded-t-2xl py-16 z-50">
            <h2>{selectedAccount?.bank_name}</h2>
            <h2>
              {selectedAccount?.date} à {selectedAccount?.time}
            </h2>
            <p>Nº de Compte{selectedAccount?.last_4}</p>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Form */}
      {displayForm ? (
        <BankAccountForm dismiss={onDismissBankAccountForm} />
      ) : (
        <></>
      )}

      <div className="w-full h-full  bg-white p-8">
        <div className="h-16 w-full  bg-white p-1 flex justify-between items-center">
          <button onClick={goBack} className="">
            <ArrowLeftIcon className="w-8" />
          </button>
          <h3>Ma Comptes bancaires </h3>
          <button className="rounded-2xl w-12 h-12 flex justify-center items-center border aspect-square">
          <Cog6ToothIcon className="w-8" />
        </button>
        </div>
        {/*  */}
        <div className="w-full my-8">
          <button
            onClick={onAdd}
            className="bg-tertiary w-full py-1 rounded-lg text-white"
          >
            AJouter un compte
          </button>
        </div>

        {/*  */}
        <div>
          {accounts.map((account) => (
            <div
              onClick={() => seeDetails(account.id)}
              key={account.id}
              className={`w-full ${
                account.status === "unread" ? "bg-white " : "bg-gray-100"
              } shadow-sm p-2 flex items-center justify-between rounded-xl gap-2`}
            >
              <div className="p-2 flex gap-2 items-center">
                <CreditCardIcon className="w-8 h-8 mx-auto text-tertiary" />
                <div>
                  <h3 className="text-md text-primary">{account.bank_name}</h3>
                  <p className="text-md text-black">
                    Nº de compte ...{account.last_4}
                  </p>
                </div>
              </div>
              {/* <div>
              <p className="text-xs text-gray-500">
                {account.date} à {account.time}
              </p>
            </div> */}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ComptesBancaires;
