import {
  ArrowLeftIcon,
  Bars3BottomRightIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";
import LogoImg from "../../assets/img/logo.png";
import { useNavigate } from "react-router-dom";

const Cug = () => {
  const navigate = useNavigate();

  const onPrint = () => {};

  const goBack = () => {
    navigate("/dashboard/profile");
  };

  return (
    <div className="w-full h-full  bg-white p-8">
      <div className="h-16 w-full  bg-white p-1 flex justify-between items-center">
        <button onClick={goBack} className="">
          <ArrowLeftIcon className="w-8" />
        </button>
        <h3>Termes et utilisations</h3>
        <button className="rounded-2xl w-12 h-12 flex justify-center items-center border aspect-square">
          <Bars3BottomRightIcon className="w-8" />
        </button>
      </div>

      {/*  */}
      <div className="space-y-4 h-[calc(100%-4rem)] overflow-y-auto p-4 text-sm font-serif">
        {/*  */}
        <div>
          <h1 className="font-bold text-primary text-lg mb-12">Accord d'utilisation de l'application NamDje</h1>

          <h2 className="font-bold text-md">Article 1 : Objet</h2>

          <p className="article">
            Le présent accord d'utilisation (ci-après "Accord") définit les
            conditions générales d'utilisation du service NamDje, accessible via
            l'application mobile NamDje (ci-après "l'Application") et les
            services associés (ci-après "les Services").
          </p>

          <h2 className="font-bold text-md">Article 2 : Définitions</h2>

          <p className="article">
            * **NamDje:** Société NamDje dont le siège
            social est situé à Lomé, et qui propose le service
            NamDje. * **Utilisateur:** Toute personne physique ou morale
            utilisant l'Application et/ou les Services. * **Application:**
            L'application mobile NamDje accessible sur les plateformes web, android et iOS. * **Services:** L'ensemble des services proposés
            par NamDje via l'Application, notamment le transfert d'argent, le
            paiement, les paiements en ligne, les paiements de salaire et autres
            transactions financières.
          </p>

          <h2 className="font-bold text-md">Article 3 : Acceptation des Conditions Générales</h2>

          <p className="article">
            L'utilisation de l'Application et/ou des Services implique
            l'acceptation sans réserve de l'ensemble des dispositions du présent
            Accord. L'Utilisateur est tenu de lire attentivement le présent
            Accord avant d'utiliser l'Application et/ou les Services.
          </p>

          <h2 className="font-bold text-md">Article 4 : Conditions d'utilisation</h2>

          <h3 className="font-bold text-md">4.1. Conditions d'accès à l'Application:</h3>

          <p className="article">
            * L'Utilisateur doit être âgé d'au moins 18 ans et avoir la capacité
            juridique pour contracter. * L'Utilisateur doit fournir des
            informations personnelles exactes et complètes lors de la création
            de son compte. * L'Utilisateur est responsable de la confidentialité
            de ses identifiants de connexion et de toutes les activités
            effectuées à partir de son compte.
          </p>

          <h3 className="font-bold text-md">4.2. Conditions d'utilisation des Services:</h3>

          <p className="article">
            * L'Utilisateur est responsable de la conformité de ses transactions
            avec la législation en vigueur. * L'Utilisateur est responsable de
            l'exactitude des informations saisies lors de chaque transaction. *
            L'Utilisateur s'engage à ne pas utiliser les Services à des fins
            illégales ou contraires à l'ordre public. * L'Utilisateur s'engage à
            ne pas partager ses identifiants de connexion avec des tiers.
          </p>

          <h2 className="font-bold text-md">Article 5 : Obligations de NamDje</h2>

          <p className="article">
            * NamDje s'engage à fournir des Services de qualité et à assurer la
            sécurité des transactions. * NamDje s'engage à respecter la
            confidentialité des informations personnelles de l'Utilisateur
            conformément à sa politique de confidentialité.
          </p>

          <h2 className="font-bold text-md">Article 6 : Exclusion de responsabilité</h2>

          <p className="article">
            * NamDje ne saurait être tenue responsable des dommages directs ou
            indirects résultant de l'utilisation ou de l'impossibilité
            d'utiliser l'Application et/ou les Services. * NamDje ne saurait
            être tenue responsable des erreurs ou omissions dans les
            informations fournies par l'Utilisateur. * NamDje ne saurait être
            tenue responsable des dommages résultant d'actions illégales ou
            frauduleuses de l'Utilisateur. * NamDje ne garantit pas que
            l'Application et/ou les Services seront disponibles en permanence et
            sans interruption.
          </p>

          <h2 className="font-bold text-md">Article 7 : Droit applicable et compétence</h2>

          <p className="article">
            Le présent Accord est régi par le droit Togo. En cas de
            litige, les tribunaux de Lomé seront seuls compétents.
          </p>

          <h2 className="font-bold text-md">Article 8 : Modification de l'Accord</h2>

          <p className="article">
            NamDje se réserve le droit de modifier à tout moment le présent
            Accord. La nouvelle version de l'Accord sera mise en ligne sur
            l'Application et/ou le site web de NamDje. L'Utilisateur est tenu de
            consulter régulièrement l'Accord pour prendre connaissance des
            éventuelles modifications.
          </p>

          <h2 className="font-bold text-md">Article 9 : Résiliation</h2>

          <p className="article">
            * NamDje peut résilier le présent Accord à tout moment et sans
            préavis en cas de violation par l'Utilisateur des conditions du
            présent Accord. * L'Utilisateur peut résilier le présent Accord à
            tout moment en supprimant son compte NamDje.
          </p>

          <h2 className="font-bold text-md">Article 10 : Contact</h2>

          <p className="article">
            Pour toute question ou réclamation concernant l'Application et/ou
            les Services, l'Utilisateur peut contacter NamDje à l'adresse
            suivante : contact@namdje.com.
          </p>

          <h2 className="font-bold text-md">Article 11 : Divers</h2>

          <p className="article">
            * Le présent Accord constitue l'intégralité de l'accord entre NamDje
            et l'Utilisateur concernant l'utilisation de l'Application et/ou des
            Services. * Si une disposition du présent Accord est déclarée nulle
            ou inapplicable, les autres dispositions resteront en vigueur.
          </p>
{/*  */}
          {/* <h2>Date et Signature</h2> */}

          <p className="article text-left font-bold w-full"><span className="font-normal">Dernière révision:</span> 26 Juillet 2024</p>
{/* 
          <p className="article">[Signature de l'Utilisateur]</p> */}

          {/* <p>
            <strong>Note:</strong> Ce modèle d'accord d'utilisation est un
            exemple et ne doit pas être considéré comme une base légale. Il est
            fortement recommandé de consulter un professionnel du droit pour
            obtenir des conseils juridiques adaptés à votre situation
            spécifique.
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Cug;
