import { getToken, isSupported } from "firebase/messaging";
import { useApi } from "./api";
import { messaging } from "../configs/firebase";

export const useNotification = () => {
  const { $post, $get, objToArray } = useApi();

  const getFcmToken = async () => {
    try {
      const canDO = await isSupported();
      if (canDO) {
        // console.log('Support notifications!')
        try {
        const token = await getToken(messaging, {
          vapidKey:
            "BOuNhdd-10rqvHU7bbYWnCbQPDolQknyfEGm7ml9Sm2DkOyAbuqJN5r81i1NwoN_h2moTb9_O-X43iRE4Uts7xs",
        });
        // console.log('token', token)
        const res = await $post("account/device/", { token }, false);
        return res;
      } catch(err) {
        console.log('Error while getting fcm token', err)
      }
      } else {
        console.log("Not support notifications!!!");
      }
    } catch (error) {
      console.log("Error while getting fcm token", error);
    }
  };

  const getNotifications = async () => {
    const { data } = await $get("account/notification/");
    if (data) {
      const result = objToArray(data);
      return result;
    }
    return [];
  };

  const setNotificationAsRead = async (notification_id: string) => {
    return await $post(
      "account/notification/mark_notification_read/",
      { notification_id },
      false
    );
  };

  return {
    getFcmToken,
    getNotifications,
    setNotificationAsRead,
  };
};
