import React, { useReducer, useState } from "react";
import { AccountType, RegisterDTO } from "../../models";
import { useNavigate } from "react-router-dom";
// import {
//   UserIcon,
//   BanknotesIcon,
//   BuildingOffice2Icon,
// } from "@heroicons/react/24/outline";
import axios from "axios";
import { useAuth } from "../../store";
import { DocumentType } from "../../models";
import LoaderImg from "../../assets/img/loader.gif";

const Register = () => {
  const [accountType] = useState<AccountType>(
    window.sessionStorage.getItem("nj_accountType") as AccountType
  );
  const [confirmPassword, setConfirmPassword] = useState("");
  const [result, setResult] = useState();
  const [documents, setDocuments] = useState<string[]>([]);
  const [document, setDocument] = useState<File>();
  const [activeDocument, setActiveDocument] = useState<string>();
  const [cni, setCni] = useState<File>();
  const [state, dispatch] = useReducer(
    (state: RegisterDTO, action: Partial<RegisterDTO>): RegisterDTO => ({
      ...state,
      ...action,
    }),
    {
      "company": "",
      username: "",
      last_name: "",
      middle_name: "",
      first_name: "",
      birth: "",
      phone: window.sessionStorage.getItem("nj_userId") as string,
      password: "",
      account_type: accountType,
    }
  );
  const [stepIndex, setStepIndex] = useState<number>(0);
  const navigate = useNavigate();
  const { register, uploadSignupDocument } = useAuth();
  const [loader, setLoader] = useState(false);

  const next = () => {
    if (stepIndex < 2) setStepIndex(stepIndex + 1);
    else {
      const index = documents.findIndex(
        (document) => document === activeDocument
      );
      if (activeDocument && index < documents.length)
        setActiveDocument(documents[index + 1]);
    }
  };

  const previous = () => {
    setStepIndex(stepIndex - 1);
  };

  const onSubmitHandler = async (e: any) => {
    console.log(state);
    const data = await register(state);
    console.log(data);
    if (!data) {
      return window.alert("Une erreur s'est produite!");
    }
    setLoader(true)
    if (data.required_document.length) {
      setDocuments(data.required_document);
      setActiveDocument(data.required_document[0]);
      next();
      setLoader(false)
    } else navigate("/dashboard/home");
  };

  const uploadHandle = async (type: DocumentType) => {
    console.log("uploads files", type);
    if (!document) return window.alert("Veuillez choisir un fichier!");
    setLoader(true)
    console.log({ name: type, document });
    const data = await uploadSignupDocument({ name: type, account_type: accountType, phone: state.phone, document });
    setDocument(undefined);
    console.log(data);
    if (!data) {
      setLoader(false)
      return window.alert("Une erreur s'est produite!");
    }
    const docIndex = documents.findIndex((doc) => doc === type);

 
    if (docIndex < documents.length - 1)
      setActiveDocument(documents[docIndex + 1]);
    else {
      window.alert(
        "Vos documents sont encours de validation. Cette opération durera moin de 48 heures."
      );
      navigate("/login");
    }
    setLoader(false)

  };

  const skipDocument = async () => {
    navigate("/dashboard/home");
  }

  return (
    <>

    {loader ? (
      <div
        className="absolute  top-0 left-0 right-0 bottom-0 w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
        onClick={($event) => {
          setLoader(false);
        }}
      >
        <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
          {/* <h2 className="text-lg uppercase font-bold">
            Entrer le montant <br />
          </h2> */}

          <img src={LoaderImg} alt="" />
          <p className="text-gray-500 italic text-sm">Connexion...</p>
        </div>
      </div>
    ) : (
      <></>
    )}
    
    <div className=" h-full flex pt-3 flex-col items-center justify-between">
      <h2 className="text-3xl text-light">Inscription</h2>
      {accountType === AccountType.CLIENT ? (
        <div className="space-y-8">
          <h3 className="text-blue-200">Compte Client</h3>
          {/* <UserIcon className="text-blue-200 animate-bounce" /> */}
        </div>
      ) : accountType === AccountType.BUSINESS ? (
        <div className="space-y-8">
          <h3 className="text-green-100">Compte Business</h3>
          {/* <BanknotesIcon className="text-blue-200 animate-bounce" /> */}
        </div>
      ) : accountType === AccountType.kIOSQUE ? (
        <div className="space-y-8">
          <h3 className="text-purple-100">Compte Kiosque</h3>
          {/* <BuildingOffice2Icon className="text-blue-200 animate-bounce" /> */}
        </div>
      ) : (
        <h2 className="text-red-500">Pas de type de compte</h2>
      )}
      <div className="w-[calc(100%+4rem)] flex flex-col justify-around h-[36rem] bg-light -mb-8 rounded-t-2xl ">
        {stepIndex === 0 ? (
          <>
            <div className="w-full space-y-8 px-4 h-72 pt-2 flex flex-col justify-around  ">
              <h3 className=" text-lg text-tertiary">Mes informations</h3>
              {accountType !== AccountType.CLIENT ? (
                <input
                  onChange={($event) =>
                    dispatch({ company: $event.target.value })
                  }
                  type="text"
                  placeholder="Entreprise"
                  className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
                />
              ) : (
                <></>
              )}

              <input
                onChange={($event) =>
                  dispatch({ first_name: $event.target.value })
                }
                type="text"
                placeholder="Nom de famille"
                className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
              />
              <input
                onChange={($event) =>
                  dispatch({ last_name: $event.target.value })
                }
                type="text"
                placeholder="Prénoms"
                className="w-full  py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md border-2 border-primary"
              />
              <input
                onChange={($event) => dispatch({ birth: $event.target.value })}
                type="date"
                placeholder="Birth"
                className="w-full py-[1.7rem]  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
              />
            </div>

            <div className=" mt-2">
              <button
                className="bg-secondary px-8 py-2 rounded-l-full rounded-r-full text-dark"
                onClick={next}
              >
                Suivant
              </button>
            </div>
          </>
        ) : stepIndex === 1 ? (
          <>
            <div className="w-full px-4 pt-8 h-40 flex flex-col space-y-4 justify-around  ">
              <input
                onChange={($event) => dispatch({ username: $event.target.value })}
                type="text"
                placeholder="Pseudo"
                className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
              />
              <input
                onChange={($event) =>
                  dispatch({ password: $event.target.value })
                }
                type="password"
                placeholder="Mot de passe"
                className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
              />
              {state.password && state.password.length < 4 ? (
                <small className="text-danger text-red-500">
                  Mot de passe trop court!
                </small>
              ) : (
                <></>
              )}
              <input
                onChange={($event) => setConfirmPassword($event.target.value)}
                type="password"
                placeholder="Confirmation de mot de passe"
                className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
              />
              {confirmPassword && state.password !== confirmPassword ? (
                <small className="text-danger text-red-500">
                  Mot de passes non identiques!
                </small>
              ) : (
                <></>
              )}
            </div>

            <div className="flex justify-around">
              <button
                className="bg-light border border-secondary text-secondary px-8 py-2 rounded-l-full rounded-r-full"
                onClick={previous}
              >
                Précédent
              </button>
              <button
                className="bg-secondary px-8 py-2 rounded-l-full rounded-r-full text-dark"
                onClick={onSubmitHandler}
              >
                Enregistrer
              </button>
            </div>
          </>
        ) : stepIndex === 2 ? (
          <div className="w-full px-4 h-full flex flex-col items-center justify-around  ">
            {activeDocument === DocumentType.SELFIE ? (
              <>
                {" "}
                <h3 className="text-xl text-tertiary mb-2">
                  Ajouter une photo d'identité
                </h3>
                <div>
                  <input
                    type="file"
                    onChange={($event: any) =>
                      setDocument($event.target.files[0])
                    }
                    className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  "
                  />
                </div>

                <div className="flex justify-between">
                  <button
                    className="bg-light border border-red-600 text-red-600 px-4 py-2 rounded-l-full rounded-r-full"
                    onClick={skipDocument}
                  >
                    Passer
                  </button>
                  <button
                    className="bg-secondary px-4 py-2   rounded-l-full rounded-r-full text-dark"
                    onClick={() => uploadHandle(DocumentType.SELFIE)}
                  >
                    Enregistrer
                  </button>

                </div>
              
              </>
            ) : activeDocument === DocumentType.ID ? (
              <>
                <h3 className="text-xl text-tertiary mb-2">
                  Ajouter votre carte d'identité
                </h3>
                <input
                  type="file"
                  onChange={($event: any) =>
                    setDocument($event.target.files[0])
                  }
                  placeholder="Ajouter votre carte d'identité"
                  className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  "
                />
                <button
                  className="bg-secondary px-4 py-2   rounded-l-full rounded-r-full text-dark"
                  onClick={() => uploadHandle(DocumentType.ID)}
                >
                  Enregistrer
                </button>
              </>
            ) : activeDocument === DocumentType.BUSINESS_CARD ?  (
              <>
               <h3 className="text-xl text-tertiary mb-2">
                  Ajouter votre carte d'entrepreneur
                </h3>
                <input
                  type="file"
                  onChange={($event: any) =>
                    setDocument($event.target.files[0])
                  }
                  placeholder="Ajouter votre carte d'identité"
                  className="w-full py-4  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  "
                />
                <button
                  className="bg-secondary px-4 py-2   rounded-l-full rounded-r-full text-dark"
                  onClick={() => uploadHandle(DocumentType.BUSINESS_CARD)}
                >
                  Enregistrer
                </button></>
            ): <></>}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  </>

  );
};

export default Register;
