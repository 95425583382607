import React from "react";
import { Outlet } from "react-router-dom";
import LogoImg from "../assets/img/logo.png";
import Loader from "../components/utils/Loader";

const AuthLayout = () => {
  // console.log(process.env.REACT_APP_LOCAL_URL)
  return (
    <>
      <div className="bg-primary h-[100dvh] w-full text-center p-8">
        <div className="w-full h-48 flex justify-center items-center">
          {/* image div */}
          <img
            src={LogoImg}
            alt="logo"
            className="rounded-full border border-primary w-40 h-40"
          />
        </div>
        <div className="h-[calc(100%-12rem)] w-full">
          {/* layout section */}
          <Outlet />
        </div>
      </div>
      <div id="recaptcha-container"></div>
    </>
  );
};

export default AuthLayout;
