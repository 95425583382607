import { ArrowLeftIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import LogoImg from "../../assets/img/logo.png";
import { useEffect, useState } from "react";
import { AccountType } from "../../models";
import { useAuth } from "../../store";
import { useNavigate } from "react-router-dom";

const Card = () => {
  const { getProfile, getCardStatus, changeCardPin, switchCardStatus } =
    useAuth();
  const [profile, setProfile] = useState<any>();
  const [cardStatus, setCardStatus] = useState<string>();
  const [pin, setPin] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [displayPinModal, setDisplayPinModal] = useState<boolean>(false);
  const [displayPasswordModal, setDisplayPasswordModal] =
    useState<boolean>(false);
  const [accountType] = useState<AccountType>(
    window.sessionStorage.getItem("nj_accountType") as AccountType
  );
  const navigate = useNavigate();

  useEffect(() => {
    const localProfile = getProfile();
    loadCardStatus();
    console.log(profile);
    localProfile ? setProfile(localProfile) : navigate("/");
  }, []);

  const loadCardStatus = async () => {
    const cardStatus = await getCardStatus();
    setCardStatus(cardStatus);
    console.log("card status: ", cardStatus);
  };

  const onPrint = () => {};

  const onSetPin = async () => {
    // display loader
    const res = changeCardPin({ password, pin });
    //  dimiss loader
    console.log(res);
    setDisplayPinModal(false);
    //  display res modal
  };

  const onLockCard = async () => {
    // display loader
    const res = await switchCardStatus({ password });
    //  dimiss loader
    setDisplayPasswordModal(false);
    //  display res modal
    loadCardStatus();
  };

  const goBack = () => {
    navigate("/dashboard/profile");
  };
  return (
    <>
      {displayPinModal ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayPinModal(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            {" "}
            <h2 className="text-lg uppercase font-bold">Changer de PIN</h2>
            <input
              onChange={($event) => setPin($event.target.value)}
              type="password"
              placeholder="Votre nouveau PIN"
              className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
            />
            <input
              onChange={($event) => setPassword($event.target.value)}
              type="password"
              placeholder="Votre mot de passe"
              className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
            />
            <button
              disabled={!password || !pin}
              onClick={onSetPin}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
            >
              Confimer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* password */}
      {displayPasswordModal ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayPasswordModal(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            {" "}
            <h2 className="text-lg uppercase font-bold">
              Confirmer l'opération
            </h2>
            <input
              onChange={($event) => setPassword($event.target.value)}
              type="password"
              placeholder="Votre mot de passe"
              className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
            />
            <button
              disabled={!password }
              onClick={onLockCard}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
            >
              Confimer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="w-full h-full  bg-white px-8 pt-8 pb-16 ">
        <div className="h-16 w-full  bg-white p-1 flex justify-between items-center">
          <button onClick={goBack} className="">
            <ArrowLeftIcon className="w-8" />
          </button>
          <h3>Ma Carte numerique </h3>
          <button className="rounded-2xl w-12 h-12 flex justify-center items-center border aspect-square">
            <Cog6ToothIcon className="w-8" />
          </button>
        </div>

        <div className="h-[calc(100%-2em)] overflow-y-auto">
          {/*  */}
          {/* card */}
          <div className="p-4">
            <div className="flex items-center justify-between">
              <h3 className="text-center text-lg text-primary mb-4">
                Carte imprimable
              </h3>
              {cardStatus === "active" ? (
                <div className="px-2 text-sm py-1 border border-green-600 text-green-600 rounded-r-full rounded-l-full">
                  active{" "}
                </div>
              ) : (
                <div className="px-2 text-sm py-1 border border-red-600 text-red-600 rounded-r-full rounded-l-full">
                  bloqué
                </div>
              )}
            </div>
            <div
              className="
             bg-teal-500 text-white w-full   shadow-lg rounded-xl  font-serif p-4"
            >
              <div className="h-full text-center text-lg mb-4">
                <h1 className="">CARTE NUMERIQUE NAMDJE</h1>
              </div>
              <div className="flex flex-col w-full items-center justify-between gap-4">
                {" "}
                <div className="w-full">
                  <img
                    src={profile?.card_qr}
                    className="w-full h-full"
                    alt=""
                  />
                </div>
                <div className="w-full flex flex-col gap-2 ">
                  <h3 className="uppercase text-md">{profile?.name}</h3>
                  {/* <h3 className="lowercase text-md text-red-500">
                @{profile?.pseudo}
              </h3> */}
                  <div>
                    <h4 className="font-mono text-gray-700 text-md fond-bold">
                      {profile?.number}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Print */}
          <div className="w-full mt-8 space-y-4">
            <button
              onClick={() => setDisplayPinModal(true)}
              className="bg-blue-500 w-full py-1 rounded-lg text-white"
            >
              Modifier mon PIN
            </button>
            <button
              onClick={onSetPin}
              className="bg-tertiary w-full py-1 rounded-lg text-white"
            >
              Imprimer ma carte
            </button>
            <button
              onClick={() => setDisplayPasswordModal(true)}
              className="bg-red-500 w-full py-1 rounded-lg text-white"
            >
              {cardStatus === "active"
                ? " Bloquer ma carte"
                : " Débloquer ma carte"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
