import { ArrowLeftIcon, Cog6ToothIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();

  const onPrint = () => {};

  const goBack = () => {
    navigate("/dashboard/profile");
  };

  return (
    <div className="w-full h-full  bg-white p-8">
      <div className="h-16 w-full  bg-white p-1 flex justify-between items-center">
        <button onClick={goBack} className="">
          <ArrowLeftIcon className="w-8" />
        </button>
        <h3>Paramètres et règlages</h3>
        <button className="rounded-2xl w-12 h-12 flex justify-center items-center border aspect-square">
          <Cog6ToothIcon className="w-8" />
        </button>
      </div>

      {/*  */}
      <div className="w-full mb-8">
        {/*  */}
        <div></div>
      </div>
    </div>
  );
};

export default Settings;
