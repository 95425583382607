
import { useApi } from "./api"

export const useBankAccount = () => {
    const { $post, $get, objToArray } = useApi()
    const getDevice = async () => "hsfadsfdaklsf"
    const getToken = async () => {
        const res = await $post('generate_ach_client_token/', {}, false)
        console.log('res', res)
        if (res && res.res !== 'Success') return
        return res.token;
    }

    
    const saveNounce = async (nonce: string, description: string) => {
        console.log(nonce, description);
        const device =  await getDevice()
        const res = await $post('save_ach_nonce/', { nonce, description, device }, false)
        console.log('res', res)
        if (res && res.res === 'Success') return {id: res.id, verified: res.verified} 
        return;
    }
 

    const getBankAccounts = async () => {
        const { data } = await $get('transaction/recharge/external/get_us_bank_accounts/')??{}
        if (data) {
            const result = objToArray(data)
            return result
        }
        return [];
    }


    const verifyCount = async (bankId: string, amount1: number, amount2: number) => {

    }


    return {
        getToken,
        getBankAccounts,
        saveNounce
    }
}