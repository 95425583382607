import React, { useState } from 'react'
import Modal from '../../components/utils/Modal'
import { useNavigate, useParams } from 'react-router-dom'
import { verify } from 'crypto';
import OtpInput from "react-otp-input";

const EditProfil = () => {
    // passaword 
    const [verifyPass,setVerifyPass]=useState(true);
    const [otp,setOtp]=useState('')
    const [number,setNumber]=useState(false)
    const navigate=useNavigate();
    const param =useParams();
    console.log(param.id)

    // 
    const passawordHandler=()=>{
        setVerifyPass(false);
    }
    // Cancel handler
    const cancelHandler=()=>{
        navigate('/dashboard/infos')
    }
  return (
    <Modal>
        {
        verifyPass && (
            <div className="w-full h-80 flex flex-col justify-around items-center  rounded-t-2xl bg-light  -mb-8 p-4 border-b-black">
              <div className='w-full flex justify-start'><span onClick={cancelHandler}>Annuler</span> </div>
            <h3 className="text-primary italic">
              Veuillez saisir votre mot de passe
            </h3>
            <input
              className="w-full py-4 text-center  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
            //   onChange={($event: any) => setPassword($event.target.value)}
              type="password"
              placeholder="Votre mot de passe"
            />
            <div>
              <button
                className="bg-secondary px-8 py-2 w-full rounded-l-full rounded-r-full border text-dark"
                onClick={passawordHandler}
              >
                Confirmez
              </button>
            </div>
          </div>)
        }
         {!verifyPass &&
         (<div className="w-full h-80 flex flex-col justify-around items-center  rounded-t-2xl bg-light  -mb-8 p-4 border-b-black">
         <h3 className="text-primary italic">
           {`Veuillez saisir votre nouveau ${param.id}`}
         </h3>
         <input
           className="w-full py-4 text-center  text-black outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
         //   onChange={($event: any) => setPassword($event.target.value)}
           type="text"
           placeholder={param.id}
         />
       </div>)}
       {number && (
        <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderSeparator={<span> </span>}
        renderInput={(props) => (
          <input
            {...props}
            className="border-b-2 border-primary text-4xl mx-2 "
          />
        )}
      />
       )}
        {
         !verifyPass &&(
            <div className='w-full  flex justify-between'>
            <button className='bg-secondary px-16 py-2 border-2' onClick={cancelHandler}>Annuler</button>
            <button className='bg-secondary px-16 py-2 border-2' onClick={cancelHandler}>Save</button>
        </div>
         )
        }
    </Modal>
  )
}

export default EditProfil