import { useApi } from "./api"


export const useSupport = () => {
    const { $post, $get, objToArray } = useApi()

    const createDiscussion = async (subject: string, message: string) => {
        try {
            const res = await $post('account/support/subject/', { subject, message }, false)
            console.log(res);
            return
        } catch (err) {
            window.alert("Echec d'enregistrement du contact!")
        }
    }

    const getDiscussions = async () => {
        const { data } = await $get('support/get_support_message_subjects/')
        console.log('Discussions',data);
        if (data) {
            // const result = objToArray(data);
            // console.log(result);
            return data;
        }
        return [];
    }

    const sendMessage = async (subject_id: string, message: string) => {
        try {
            const res = await $post('account/support/message/', { subject_id, message }, false)
            console.log(res);
            return
        } catch (err) {
            window.alert("Echec d'enregistrement du contact!")
        }
    }

    const getMessages = async (subject_id: string) => {
        const { data } = await $post('account/support/message/get_unread_support_messages/', { subject_id }, false)
        if (data) {
            const result = objToArray(data);
            console.log(result);
            return data;
        }
        return [];
    }

    const getUnreadMessages = async (subject_id: string) => {
        const { data } = await $post('account/support/message/get_support_messages/', { subject_id }, false)
        if (data) {
            const result = objToArray(data);
            console.log(result);
            return result;
        }
        return [];
    }

    return { createDiscussion, getDiscussions, getUnreadMessages, sendMessage, getMessages }

}
