import { AccountType } from "../models";

export const asideBarMenuList: {
  title: string;
  path: string | null;
  subtitles: { title: string; path: string }[] | null;
  roles: AccountType[];
}[] = [
  // {
  //     title:'Salaire',
  //     path:null,
  //     subtitles:[
  //         {
  //             title:"Employés",
  //             path:'/advanced/salaires/employes'
  //         },
  //         {
  //             title:"Versement",
  //             path:'/advanced/salaires/versements'
  //         },
  //         {
  //             title:"Paramétrage",
  //             path:'/advanced/salaires/options'
  //         },
  //     ]
  // },
  {
    title: "Mes Tontines",
    path: "/advanced/tontines/lists",
    subtitles: null,
    roles: [AccountType.CLIENT, AccountType.BUSINESS],
    // subtitles:[

    //     {
    //         title:"Mes tontines",
    //         path:'/advanced/tontines/lists'
    //     },
    //     {
    //         title:"Parametres",
    //         path:'/advanced/tontines/settings'
    //     },
    //     // {
    //     //     title:"Option",
    //     //     path:""
    //     // },
    // ]
  },
  {
    title: "Salaires",
    path: "/advanced/salary/employees",
    subtitles: null,
    roles: [AccountType.BUSINESS],

    // subtitles:[
    //     {
    //         title:"Mes employés",
    //         path:'/advanced/salary/employees',
    //     },
    //     {
    //         title:"Parametres",
    //         path:'/advanced/salary/employee/0'
    //     }
    // ]
  },
  // {
  //     title:'Lever de fond',
  //     path:null,
  //     subtitles:null
  // },
];
