import { JoinTontineDTO, TontineDTO, MakeTontinePaymentDTO } from "../models";
import { useApi } from "./api";

const useTontine = () => {
  const { $post, $get, $patch, $response } = useApi();
  const addNewTotineGroupe = async (newTotine: TontineDTO) => {
    const res = await $post("transaction/tontine/", newTotine);
    return $response(res);
  };

  const activateTontine = async (data: {
    id: string;
    password: string;
    start_date?: string;
  }) => {
    const res = await $patch("transaction/tontine/activation/", {
      ...data,
      status: "active",
    });
    return $response(res);
  };

  const getTontines = async () => {
    const { data } = await $get("transaction/tontine/");
    return data ?? [];
  };

  const getTontine = async (id: string) => {
    const { data } = await $get(`transaction/tontine/${id}`);
    return data;
  };

  const getMembers = async (id: string) => {
    const { data } = await $get(
      `transaction/tontine/get_tontine_members?tontine_id=${id}`
    );
    return data ?? [];
  };

  const getMemberTontines = async () => {
    const { data } = await $get(`transaction/tontine/get_member_tontines/`);
    return data ?? [];
  };

  const getMemberTontine = async (id: string) => {
    const { data } = await $get(
      `transaction/tontine/get_member_tontine?tontine_member_id=${id}`
    );
    return data ?? [];
  };

  const getMember = async (id: string) => {
    const { data } = await $get(`transaction/tontine_member/${id}`);
    return data ?? {};
  };

  const joinTontine = async (data: JoinTontineDTO) => {
    // console.log(data);
    const res = await $post(`transaction/tontine/join_tontine/`, data);
    return $response(res);
  };

  const blockMember = async (data: any) => {
    const res = await $post(
      `transaction/tontine/update_tontine_member_status/`,
      { ...data, status: "bloqued" }
    );
    return $response(res);
  };

  const approveMember = async (data: any) => {
    const res = await $post(
      `transaction/tontine/update_tontine_member_status/`,
      { ...data,  status: "active" }
    );
    return $response(res);
  };

  const rejectMember = async (data: any) => {
    const res = await $post(
      `transaction/tontine/update_tontine_member_status/`,
      { ...data, status: "rejected" }
    );
    return $response(res);
  };

  const approveAllMembers = async (data: any) => {
    const res = await $post(
      `transaction/tontine/update_all_pending_tontine_members_status/`,
      { ...data, status: "active" }
    );
    return $response(res);
  };

  const rejectAllMembers = async (data: any) => {
    const res = await $post(
      `transaction/tontine/update_all_pending_tontine_members_status/`,
      { ...data, status: "rejected" }
    );
    return $response(res);
  };

  const changeMemberOrder = async (data: {
    tontine_id: string;
    member_round: number;
    password: string;
    member_id: string;
  }) => {
    const res = await $post(
      `transaction/tontine/update_tontine_member_round/`,
      data
    );
    return $response(res);
  };

  const startTonine = async (data: {
    tontine_id: string;
    password: string;
    start_date: string;
  }) => {
   
    console.log('data',data);
    const res = await $patch(`transaction/tontine/${data.tontine_id}/`, { status: "active", start_date: data.start_date, password: data.password });
    return $response(res);
  };

  const payMember = async (data: MakeTontinePaymentDTO) => {
    const res = await $post(`transaction/tontine/make_tontine_payment/`, data);
    return $response(res);
  };

  const restartTontine = async (tontine_id: string) => {
    const res = await $post(`transaction/tontine/restart_tontine/`, {
      tontine_id,
    });
    return $response(res);
  };

  return {
    addNewTotineGroupe,
    activateTontine,
    getTontines,
    getTontine,
    getMembers,
    getMember,
    getMemberTontines,
    getMemberTontine,
    joinTontine,
    blockMember,
    payMember,
    restartTontine,
    approveMember,
    rejectMember,
    approveAllMembers,
    rejectAllMembers,
    changeMemberOrder,
    startTonine,
  };
};
export { useTontine };
