import { useEffect, useMemo, useState } from "react";
import { useContact } from "../store";
import { AccountType } from "../models";
import { UserIcon } from "@heroicons/react/24/outline";

type IProps = { accountType: AccountType; cb: (receiver?: any) => void };

export default function ContactsList({ accountType, cb }: IProps) {
  const [contacts, setContacts] = useState<any[]>([]);
  const [computedContacts, setComputedContacts] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState<string>("");

  const { getContacts } = useContact();

    useMemo(() => {
        if (!searchValue) return setComputedContacts(contacts)
        const computedVal: any[] = contacts.
        filter(contact => contact.name.toLowerCase().includes(searchValue.toLowerCase()) 
     || contact.phone.includes(searchValue));
     setComputedContacts(computedVal)
  }, [searchValue, contacts])

  useEffect(() => {
    loadContacts();
  }, []);

  const loadContacts = async () => {
    const contacts = await getContacts(accountType);
    // console.log("contacts", contacts);
    setContacts(contacts);

  };

  const handleSelectContact = (contact: any) => {
    cb(contact);
  };

  return (
    <div
      className="absolute w-full h-screen bg-black bg-opacity-70  z-40 flex flex-col justify-end"
      onClick={() => cb()}
    >
      <div
        onClick={($event) => {
          $event.stopPropagation();
        }}
        className="mx-auto bg-white bg-opacity-100 w-full rounded-t-2xl py-8 space-y-3 p-8 z-50"
      >
        <input
          className="text-center w-full border py-2 rounded-l-full rounded-r-full"
          type="search"
          placeholder="Rechercher..."
          onChange={($event) => setSearchValue($event.target.value)}
        />
        {computedContacts.length ? (
          computedContacts.map((contact) => (
            <div
              key={contact.id}
              onClick={() => handleSelectContact(contact)}
              className={`w-full ${
                contact.status === "unread" ? "bg-white " : "bg-gray-100"
              } shadow-sm p-2 flex items-center justify-between rounded-xl gap-2`}
            >
              <div className="p-2 flex gap-4 items-center">
                {/* <UserIcon className="w-8 h-8 mx-auto text-tertiary" /> */}
                <img className="w-8 h-8 rounded-full" src={contact.avatar} alt="" />
                <div>
                  <h3 className="text-md text-primary">{contact.name}</h3>
                  <p className="text-sm text-black">
                    {contact.phone}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="w-full h-96 flex justify-center items-center">
            <p className="text-gray-700">Aucun contact trouvé!</p>
          </div>
        )}
      </div>
    </div>
  );
}
