import {
  ArrowLeftIcon,
  ArrowLeftOnRectangleIcon,
  ArrowPathIcon,
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  CheckCircleIcon,
  CreditCardIcon,
  HomeIcon,
  InformationCircleIcon,
  LightBulbIcon,
  PaperClipIcon,
  PhoneIcon,
  QrCodeIcon,
  ShoppingCartIcon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import LogoImg from "../../assets/img/logo.png";
import { useAuth, useBankAccount, useContact, useOperation } from "../../store";
import { useNavigate } from "react-router-dom";
import { AccountType } from "../../models";
import { QrScanner } from "@yudiel/react-qr-scanner";
import PhoneInput from "react-phone-number-input";
import PayPalBtns from "../../components/PayPalBtns";
import BankAccountForm from "../../components/BankAcountForm";
import ContactsList from "../../components/ContactsList";
import LoaderImg from "../../assets/img/loader.gif";
import Sidebar from "../../components/utils/Sidebar";

import backGround from '../../assets/img/back-ground-1.jpg';


const Home = () => {
  const { getProfile } = useAuth();
  const {
    readQrCode,
    getOperations,
    getBalance,
    getReceiver,
    transfert,
    withdraw,
    deposit,
    pay,
    generateExternalRechargeCode,
    savePapaypalRecharge,
    rechargeWithBankAccount,
    readInvoiceQrcode,
    generateInvoice,
    listenInvoiceValidation,
    payInvoice,
  } = useOperation();
  const { logout } = useAuth();
  const { getBankAccounts } = useBankAccount();
  const { saveContact } = useContact();
  const { verifyAccount } = useAuth();
  const [displayMenu, setDisplayMenu] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>();
  const [balance, setBalance] = useState<number>();
  const [operations, setOperations] = useState<any[]>([]);
  const [displayBalance, setDisplayBalance] = useState(false);
  const [displayQR, setDisplayQR] = useState(false);
  const [displayDepositQR, setDisplayDepositQR] = useState(false);
  const [depositStep, setDepositStep] = useState<number>(0);

  const [displayOperationMethod, setDisplayOperationMethod] = useState(false);
  const [displayOperationKioskMethod, setDisplayOperatioKiosknMethod] =
    useState(false);
  const [displayWithdrawalMethod, setDisplayWithdrawalMethod] = useState(false);

  const [displayAmountModal, setDisplayAmountModal] = useState(false);
  const [displayPasswordModal, setDisplayPasswordModal] = useState(false);
  const [displayResModal, setDisplayResModal] = useState(false);
  const [loader, setLoader] = useState<{ timeout: number; message: string }>();
  const [response, setResponse] = useState<{
    success: boolean;
    message: string;
  }>();

  const [displayContactsList, setDisplayContactsList] = useState(false);

  const [operationType, setoperationType] = useState<any>();
  const [usePseudo, setUsePseudo] = useState(false);
  const [receiver, setReceiver] = useState<any>();
  const [chekReceiverRes, setCheckReceiverRes] = useState<any>();

  const [amount, setAmount] = useState(0);
  const [externalRechargeRes, setExternalRechargeRes] = useState<any>();
  const [password, setPassword] = useState("");

  const [phone, setPhone] = useState<any>();
  const [pseudo, setPseudo] = useState("");
  const [scanning, setScanning] = useState(false);

  const [bankAccounts, setBankAccounts] = useState<any[]>([]);
  const [displayAccountForm, setDisplayAccountForm] = useState<boolean>(false);
  const [saveContactChecked, setSaveContactChecked] = useState(false);
  const [displayPaymentMethod, setDisplayPaymentMethod] = useState(false);
  const [invoice, setInvoice] = useState<any>();
  const [displayInvoiceModal, setDisplayInvoiceModal] =
    useState<boolean>(false);
  const [displayBusinessInvoiceForm, setDisplayBusinessInvoiceForm] =
    useState<boolean>(false);
  const [description, setDescription] = useState<any>();
  const [invoiceResponse, setInvoiceResponce] = useState<any>();
  const [displayBusinessInvoiceQr, setDisplayBusinessInvoiceQR] =
    useState<boolean>();

  const navigate = useNavigate();

  const [accountType, setAccountType] = useState<AccountType>(
    window.sessionStorage.getItem("nj_accountType") as AccountType
  );

  const [operationAccountType, setOperationAccountType] = useState<AccountType>(
    AccountType.CLIENT
  );

  useEffect(() => {
    const localProfile = getProfile();
    setProfile(localProfile);
    updateBalance();
    getLastOperations();
    loadBankAccounts();
    // console.log(profile);
    localProfile ? setProfile(localProfile) : navigate("/");
  }, []);

  const updateBalance = async () => {
    const balance = await getBalance();
    setBalance(+balance);
  };

  const switchBalanceDisplay = async () => {
    updateBalance();
    setDisplayBalance(!displayBalance);
    if (!displayBalance) setTimeout(() => setDisplayBalance(false), 5000);
  };

  const switchDisplayQr = (val: boolean) => {
    setDisplayQR(val);
  };

  const getLastOperations = async () => {
    const data = await getOperations({});
    // const formatedData: any[] = [];
    if(!data)return []
    // Object.keys(data).map((item, i) => {
    //   const operation = Object.keys(data[item]).map((key, i) => ({
    //     id: key,
    //     date: item,
    //     ...data[item][key],
    //   }));
    //   formatedData.push(...operation);
    // });
    // console.log("last operations", formatedData);
    setOperations(data?.slice(0, 5));
  };

  const loadBankAccounts = async () => {
    const bankAccounts = await getBankAccounts();
    // console.log("bankAccounts", bankAccounts);
    setBankAccounts(bankAccounts);
  };

  const onInitOperation = (operationType: any) => {
    setoperationType(operationType);
    if (operationType === "transfert") {
      setOperationAccountType(AccountType.CLIENT);
      setDisplayOperationMethod(true);
    } else if (operationType === "withdraw") {
      setOperationAccountType(AccountType.kIOSQUE);
      setDisplayWithdrawalMethod(true);
      setScanning(true);
    } else if (operationType === "deposit") {
      setOperationAccountType(AccountType.kIOSQUE);
      setDisplayDepositQR(true);
    } else if (operationType === "payment") {
      setOperationAccountType(AccountType.BUSINESS);
      setDisplayPaymentMethod(true);
      setScanning(true);
    }
  };

  const onInitBusinessOperation = (operationType: any) => {
    console.log(operationType);
    setoperationType(operationType);
    if (operationType === "withdraw") {
      setDisplayOperationMethod(true);
      setScanning(true);
    } else {
      switchDisplayQr(true);
    }
  };

  const onInitKiiosqueOperation = (operationType: any) => {
    console.log(operationType);
    setoperationType(operationType);
    if (operationType === "deposit") {
      setDisplayOperatioKiosknMethod(true);
      // setScanning(true);
    } else {
      switchDisplayQr(true);
    }
  };

  const onCheckIdentfier = async () => {
    const req: any = { account_type: operationAccountType };
    if (usePseudo) req["username"] = pseudo;
    else req["phone"] = phone;

    const res = await verifyAccount(req);
    setCheckReceiverRes(res);
    // console.log("result", res);
  };

  const onValidateIdentifier = async () => {
    console.log("runed");
    displayLoader();

    let req: any = { receiver_type: accountType};
    if (usePseudo) req["receiver_username"] = pseudo;
    else {
      let receiver_type;
      if (operationType === "transfert") receiver_type = AccountType.CLIENT;
      if (operationType === "withdraw" || operationType === "deposit") {
        setDisplayWithdrawalMethod(false);
        receiver_type = AccountType.kIOSQUE;
      }

      if (operationType === "payment") receiver_type = AccountType.BUSINESS;
      req = { receiver_id: phone, receiver_type };
    }
    const receiver = await getReceiver(req);
    if (receiver.res === "failed") {
      window.alert(receiver.message);
      return;
    }
    setReceiver(receiver);
    setDisplayOperationMethod(false);
    setDisplayWithdrawalMethod(false);
    setDisplayPaymentMethod(false);
    setDisplayAmountModal(true);
  };

  const onValidateKiosqueIdentifier = async () => {
    console.log("runed");
    displayLoader();
    let req: any = {};
    if (usePseudo) req["receiver_username"] = pseudo;
    else {
      let receiver_type;
      if (operationType === "transfert") receiver_type = AccountType.CLIENT;
      if (operationType === "withdraw" || operationType === "deposit") {
        setDisplayWithdrawalMethod(false);
        receiver_type = AccountType.CLIENT;
      }

      if (operationType === "payment") receiver_type = AccountType.BUSINESS;
      req = { receiver_id: phone, receiver_type };
    }
    const receiver = await getReceiver(req);
    if (receiver.res === "failed") {
      window.alert(receiver.message);
      return;
    }
    setReceiver(receiver);
    setDisplayOperationMethod(false);
    setDisplayWithdrawalMethod(false);
    setDisplayPaymentMethod(false);
    setDisplayAmountModal(true);
  };

  const onValidateAmount = () => {
    displayLoader();
    setDisplayAmountModal(false);
    setDisplayPasswordModal(true);
  };

  const onMakeOperation = async () => {
    if (accountType === AccountType.kIOSQUE)
      return await onMakeKioskOperation();
    displayLoader();
    console.log(receiver, amount, password);
    setDisplayPasswordModal(false);
    let res;
    switch (operationType) {
      case "transfert":
        res = await transfert(
          AccountType.CLIENT,
          receiver.id,
          amount,
          password
        );
        break;
      case "deposit":
        res = await deposit(AccountType.kIOSQUE, receiver.id, amount, password);
        break;
      case "withdraw":
        res = await withdraw(
          AccountType.kIOSQUE,
          receiver.id,
          amount,
          password
        );
        break;
      case "payment":
        res = await pay(AccountType.BUSINESS, receiver.id, amount, password);
        break;
      default:
        console.log("Operation not supported!");
    }
    console.log(res);
    displayResponse(res.data);
    getLastOperations();
    if (saveContactChecked) await saveContact(receiver.id);
  };

  const onMakeKioskOperation = async () => {
    displayLoader();
    console.log(receiver, amount, password);
    setDisplayPasswordModal(false);
    let res;
    switch (operationType) {
      case "transfert":
        res = await transfert(
          AccountType.CLIENT,
          receiver.id,
          amount,
          password
        );
        break;
      case "deposit":
        res = await deposit(AccountType.CLIENT, receiver.id, amount, password);
        break;
      case "withdraw":
        res = await withdraw(AccountType.CLIENT, receiver.id, amount, password);
        break;
      case "payment":
        res = await pay(AccountType.BUSINESS, receiver.id, amount, password);
        break;
      default:
        console.log("Operation not supported!");
    }
    console.log(res);
    displayResponse(res.data);
    getLastOperations();
    if (saveContactChecked) await saveContact(receiver.id);
  };

  const scanQr = async (val: string) => {
    if (val) setScanning(false);
    if (val.includes("paiement")) {
      const invoice = await readInvoiceQrcode(val);
      if (invoice.type !== 'invoice')  {
        setDisplayPaymentMethod(false);
        setDisplayInvoiceModal(true);
        displayResponse({success: false, message:'Attention! Ce Qr n\'est pas une facture'})
        return
      }
      setInvoice(invoice);
      setDisplayPaymentMethod(false);
      setDisplayInvoiceModal(true);
      return;
    }
    const receiver = await readQrCode(val);
    // receiver.id = 'receiver_' + receiver.id;
    setReceiver(receiver);
    setDisplayWithdrawalMethod(false);
    setDisplayOperatioKiosknMethod(false);
    setDisplayOperationMethod(false);
    setDisplayAmountModal(true);
  };

  const onPayInvoice = async () => {
    displayLoader();
    if (!receiver) {
      const res: any = await payInvoice(password, invoice.code);
      setResponse({ success: res.res === "Success", message: res.message });

      return setDisplayResModal(true);
    }
    const res = await pay(
      AccountType.BUSINESS,
      receiver.id,
      amount,
      password,
      invoice.code
    );
    setDisplayResModal(true);
    console.log(res);
  };

  const selectContact = async (contact?: any) => {
    setDisplayContactsList(false);
    if (contact) {
      const receiver = await getReceiver({ contact_id: contact.id });
      console.log(receiver);
      setReceiver(receiver);
      setDisplayOperationMethod(false);
      setDisplayWithdrawalMethod(false);
      setDisplayPaymentMethod(false);
      setDisplayAmountModal(true);
    }
  };

  const checkDepositAmount = async () => {
    console.log(amount);
    const res = await generateExternalRechargeCode(amount);
    console.log(res);
    if (res && res.code) {
      setExternalRechargeRes(res);
      setDepositStep(3);
    }
  };

  const savePaypalRechargeCb = async (orderID: string) => {
    console.log(orderID);
    const res: boolean = await savePapaypalRecharge(
      orderID,
      externalRechargeRes.code
    );
    if (res) {
      setDepositStep(4);
      getLastOperations();
    }
  };

  const payWithBankAccount = async (id: string) => {
    console.log(id);
  };

  const verifyBankAccount = async (id: string) => {};

  const addBankAccount = async () => {
    setDisplayAccountForm(true);
  };

  const onDismissBankAccountForm = async (data?: {
    id: string;
    verified: boolean;
  }) => {
    setDisplayAccountForm(false);
    console.log(data);
    if (!data) {
      return;
    }
    loadBankAccounts();
    if (!data.verified) {
      return setDisplayAccountForm(false);
    }

    // setDepositStep(5)
    rechargeWithBankAccount(data.id, externalRechargeRes.code);
  };

  const displayResponse = (res: any) => {
    setResponse({ success: res.res === "Success", message: res.message });
    setDisplayResModal(true);
  };

  const displayLoader = (
    message = "Veuillez patienter...",
    timeout = 3,
    cb?: () => void
  ) => {
    setLoader({ timeout, message });
    window.setTimeout(() => dismissLoader(), timeout * 1000);
  };

  const dismissLoader = () => {
    setLoader(undefined);
  };

  // Business methods
  const onGenerateInvoice = async () => {
    const data = await generateInvoice(amount, description);
    console.log(data);
    setInvoiceResponce(data);
    setDisplayBusinessInvoiceForm(false);
    setDisplayBusinessInvoiceQR(true);
    const interval = window.setInterval(async () => {
      const result = await listenInvoiceValidation(data.code);
      if (result.res === "Success") {
        window.clearInterval(interval);
        setResponse({
          success: result.res === "Success",
          message: `${result.sender} vous a payé ${result.amount}.`,
        });
        setDisplayBusinessInvoiceForm(false);
        setDisplayResModal(true);
      }
    }, 1000 * 5);
  };

  const switchAccount = (event: any) => {
    if (event.target.value === accountType) return;
    console.log(event.target.value);
    setAccountType(event.target.value);
    window.sessionStorage.setItem("nj_accountType", event.target.value);
    logout(accountType);
    navigate("/login");
  };

  return (
    // QrCode
    <div className="h-full w-full overflow-y-hidden">
      {displayMenu ? <Sidebar /> : <></>}
      {displayQR ? (
        <div
          className="absolute w-full h-screen bg-black bg-opacity-70  z-40 flex flex-col justify-end"
          onClick={() => switchDisplayQr(false)}
        >
          <div className="mx-auto bg-white bg-opacity-100 w-full rounded-t-2xl p-16 z-50">
            <h3 className="text-center">
              Presenter votre QR code pour effectuer l'opération
            </h3>
            <img src={profile?.qr_image} className="w-48 h-48 mx-auto" alt="QR Image" />
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Deposit QrCode */}
      {displayDepositQR ? (
        <div
          className="absolute w-full h-screen bg-black bg-opacity-70  z-40 flex flex-col justify-end"
          onClick={() => {
            setDisplayDepositQR(false);
            setDepositStep(0);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 text-primary bg-white bg-opacity-100 w-full rounded-t-2xl pb-16 pt-8 z-50"
          >
            <h2 className="text-lg  text-center uppercase font-bold mb-8">
              Dépot
            </h2>
            <div className="w-full gap-4 flex ">
              {!depositStep ? (
                <>
                  <div
                    onClick={() => setDepositStep(1)}
                    className="w-1/2 flex flex-col p-4 shadow-sm hover:shadow-lg border border-primary rounded-lg"
                  >
                    <HomeIcon />
                    Chez un Kiosque
                  </div>
                  <div
                    onClick={() => setDepositStep(2)}
                    className="w-1/2 flex flex-col shadow-sm hover:shadow-lg border border-primary p-4 rounded-lg"
                  >
                    <CreditCardIcon />
                    Autre méthodes
                  </div>
                </>
              ) : depositStep === 1 ? (
                <div className="">
                  <h3 className="text-center text-gray-500">
                    Faites scanner ce qr code pour vous faire recharger votre
                    compte.
                  </h3>
                  <img
                    src={profile?.qr_image}
                    className="w-48 h-48 mx-auto"
                    alt=""
                  />
                </div>
              ) : depositStep === 2 ? (
                <div className="w-full flex flex-col justify-between h-36 text-center">
                  <input
                    onChange={($event) => setAmount(+$event.target.value)}
                    type="number"
                    placeholder="Veuillez saisir le montant"
                    className="w-full py-4 text-center text-xl  text-primary outline-none rounded-l-full rounded-r-full p-2 text-md  border-2 border-primary"
                  />
                  <button
                    disabled={!amount}
                    className="bg-secondary px-8 py-2   rounded-l-full rounded-r-full text-dark"
                    onClick={checkDepositAmount}
                  >
                    Continuer
                  </button>
                </div>
              ) : depositStep === 3 ? (
                <div className="w-full flex flex-col justify-between h-36 text-center">
                  <h3>
                    Utiliser compte bancaire US: ${" "}
                    {externalRechargeRes.ach_amount_in_usd}
                  </h3>
                  <small className="text-gray-700 text-xs my-1">
                    Cette méthode peut prendre jusqu'à sept(7) jours pour être
                    validé
                  </small>
                  {bankAccounts
                    .filter((account) => account.verified)
                    .map((account) => (
                      <button
                        key={account.id}
                        className="bg-primary text-white px-8 py-2  mb-4 rounded-l-full rounded-r-full"
                        onClick={() => payWithBankAccount(account.id)}
                      >
                        Payer avec {account.bank_name}
                      </button>
                    ))}
                  {bankAccounts
                    .filter((account) => !account.verified)
                    .map((account) => (
                      <button
                        key={account.id}
                        className="bg-primary text-white px-8 py-2  mb-4 rounded-l-full rounded-r-full"
                        onClick={() => verifyBankAccount(account.id)}
                      >
                        Verifier le compte {account.bank_name}
                      </button>
                    ))}
                  <button
                    className="bg-primary text-white px-8 py-2  mb-4 rounded-l-full rounded-r-full"
                    onClick={addBankAccount}
                  >
                    Ajouter un compte bancaire
                  </button>
                  <hr />
                  <h3>
                    Utiliser autres methodes: ${" "}
                    {externalRechargeRes.amount_in_usd}
                  </h3>
                  <PayPalBtns
                    amount={externalRechargeRes.amount_in_usd.toString()}
                    currencyCode="USD"
                    cb={savePaypalRechargeCb}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* identifier */}
      {displayOperationMethod ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayOperationMethod(false);
            setScanning(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            <h2 className="text-lg uppercase font-bold">{operationType}</h2>
            {scanning ? (
              <QrScanner
                onDecode={scanQr}
                onError={(error: any) => console.log(error?.message)}
              />
            ) : (
              <>
                {usePseudo ? (
                  <input
                    onChange={($event) => setPseudo($event.target.value)}
                    onKeyUp={onCheckIdentfier}
                    type="text"
                    placeholder="Entrer le pseudo"
                    className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
                  />
                ) : (
                  <PhoneInput
                    className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary  rounded p-2 text-primary text-xl"
                    placeholder="Entrer le numero de téléphone"
                    value={phone}
                    onChange={setPhone}
                    onKeyUp={onCheckIdentfier}
                  />
                )}
                <small className="text-secondary absolute uppercase">
                  {chekReceiverRes?.name}
                </small>
                <div className="flex gap-4 items-center text-sm text-gray-700">
                  <input
                    onChange={($event) =>
                      setSaveContactChecked($event.target.checked)
                    }
                    type="checkbox"
                    name=""
                    id=""
                  />
                  Sauvegarder en tant que contact
                </div>

                <button
                  disabled={!pseudo && !phone}
                  onClick={onValidateIdentifier}
                  className="bg-secondary px-16 py-2 rounded-l-full w-full rounded-r-full  text-dark"
                >
                  Continuer
                </button>
                <div className="w-full space-y-2 text-center">
                  <div className="w-full flex justify-between items-center">
                    <hr className="w-2/5" />
                    <span className="uppercase font-serif">ou</span>
                    <hr className="w-2/5" />
                  </div>
                  <div className="w-full space-y-4">
                    <button
                      onClick={() => setDisplayContactsList(true)}
                      className="bg-cyan-700 flex gap-4 items-center justify-center w-full py-2 rounded-l-full rounded-r-full h-fit text-white"
                    >
                      <UserGroupIcon className="w-4 h-4" />
                      Transférer à un contact
                    </button>
                    <button
                      onClick={() => setScanning(true)}
                      className="p-2 flex w-full justify-center  font-bold gap-4 items-center text-white  rounded-l-full rounded-r-full bg-yellow-500"
                    >
                      <QrCodeIcon className="w-4 h-4 text-white" />
                      <span className="text-sm">
                        Scanner le QR Code du receveur
                      </span>
                    </button>
                    {usePseudo ? (
                      <button
                        onClick={() => setUsePseudo(false)}
                        className="font-bold py-2 flex justify-center gap-4 w-full items-center text-white  rounded-l-full rounded-r-full bg-primary"
                      >
                        <PhoneIcon className="w-4 h-4  text-white" />
                        <span className="text-sm">
                          Utiliser le numero de téléphone
                        </span>
                      </button>
                    ) : (
                      <button
                        onClick={() => setUsePseudo(true)}
                        className="font-bold py-2 flex gap-4 w-full justify-center items-center text-white  rounded-l-full rounded-r-full bg-primary"
                      >
                        <UserIcon className="w-4 h-4  text-white" />
                        <span className="text-sm">
                          Utiliser le Pseudo du receveur
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* Withdraw */}
      {displayWithdrawalMethod ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayWithdrawalMethod(false);
            setScanning(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            <h2 className="text-lg uppercase font-bold">Retrait</h2>
            {scanning ? (
              <>
                {" "}
                <small>Scannner le Qr Code du kiosque</small>
                <QrScanner
                  onDecode={scanQr}
                  onError={(error: any) => console.log(error?.message)}
                />
              </>
            ) : (
              <>
                {usePseudo ? (
                  <input
                    onChange={($event) => setPseudo($event.target.value)}
                    onKeyUp={onCheckIdentfier}
                    type="text"
                    placeholder="Entrer le pseudo"
                    className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
                  />
                ) : (
                  <PhoneInput
                    className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary  rounded p-2 text-primary text-xl"
                    placeholder="Entrer le numero de téléphone"
                    value={phone}
                    onChange={setPhone}
                    onKeyUp={onCheckIdentfier}
                  />
                )}
                <small className="text-secondary absolute uppercase">
                  {chekReceiverRes?.name}
                </small>
                <div className="flex gap-4 items-center text-sm text-gray-700">
                  <input
                    onChange={($event) =>
                      setSaveContactChecked($event.target.checked)
                    }
                    type="checkbox"
                    name=""
                    id=""
                  />
                  Sauvegarder le kiosque
                </div>

                <button
                  disabled={!pseudo && !phone}
                  onClick={onValidateIdentifier}
                  className="bg-secondary px-16 py-2 rounded-l-full w-full rounded-r-full  text-dark"
                >
                  Continuer
                </button>
              </>
            )}
            <div className="w-full space-y-2 text-center">
              <div className="w-full flex justify-between items-center">
                <hr className="w-2/5" />
                <span className="uppercase font-serif">ou</span>
                <hr className="w-2/5" />
              </div>
              <div className="w-full space-y-4">
                <button
                  onClick={() => setDisplayContactsList(true)}
                  className="bg-cyan-700 flex gap-4 items-center justify-center w-full py-2 rounded-l-full rounded-r-full h-fit text-white"
                >
                  <HomeIcon className="w-4 h-4" />
                  Retirer chez un kiosque sauvegardé
                </button>

                {scanning ? (
                  <button
                    onClick={() => {
                      setUsePseudo(true);
                      setScanning(false);
                    }}
                    className="font-bold py-2 flex gap-4 w-full justify-center items-center text-white  rounded-l-full rounded-r-full bg-primary"
                  >
                    <UserIcon className="w-4 h-4  text-white" />
                    <span className="text-sm">
                      Utiliser le Pseudo du receveur
                    </span>
                  </button>
                ) : (
                  <button
                    onClick={() => setScanning(true)}
                    className="p-2 flex w-full justify-center  font-bold gap-4 items-center text-white  rounded-l-full rounded-r-full bg-yellow-500"
                  >
                    <QrCodeIcon className="w-4 h-4 text-white" />
                    <span className="text-sm">
                      Scanner le QR Code du receveur
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Payment */}
      {displayPaymentMethod ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayPaymentMethod(false);
            // setScanning(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            <h2 className="text-lg uppercase font-bold">Paiement</h2>
            {scanning ? (
              <>
                {" "}
                <small>Scannner le Qr Code du Marchand</small>
                <QrScanner
                  onDecode={scanQr}
                  onError={(error: any) => console.log(error?.message)}
                />
              </>
            ) : (
              <>
                {usePseudo ? (
                  <input
                    onChange={($event) => setPseudo($event.target.value)}
                    onKeyUp={onCheckIdentfier}
                    type="text"
                    placeholder="Entrer le pseudo"
                    className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
                  />
                ) : (
                  <PhoneInput
                    className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary  rounded p-2 text-primary text-xl"
                    placeholder="Entrer le numero de téléphone"
                    value={phone}
                    onChange={setPhone}
                    onKeyUp={onCheckIdentfier}
                  />
                )}
                <small className="text-secondary absolute uppercase">
                  {chekReceiverRes?.name}
                </small>
                <div className="flex gap-4 items-center text-sm text-gray-700">
                  <input
                    onChange={($event) =>
                      setSaveContactChecked($event.target.checked)
                    }
                    type="checkbox"
                    name=""
                    id=""
                  />
                  Sauvegarder le marchand
                </div>

                <button
                  disabled={!pseudo && !phone}
                  onClick={onValidateIdentifier}
                  className="bg-secondary px-16 py-2 rounded-l-full w-full rounded-r-full  text-dark"
                >
                  Continuer
                </button>
              </>
            )}
            <div className="w-full space-y-2 text-center">
              <div className="w-full flex justify-between items-center">
                <hr className="w-2/5" />
                <span className="uppercase font-serif">ou</span>
                <hr className="w-2/5" />
              </div>
              <div className="w-full space-y-4">
                <button
                  onClick={() => setDisplayContactsList(true)}
                  className="bg-cyan-700 flex gap-4 items-center justify-center w-full py-2 rounded-l-full rounded-r-full h-fit text-white"
                >
                  <HomeIcon className="w-4 h-4" />
                  Payer chez un marchand sauvegardé
                </button>

                {scanning ? (
                  <button
                    onClick={() => {
                      setUsePseudo(true);
                      setScanning(false);
                    }}
                    className="font-bold py-2 flex gap-4 w-full justify-center items-center text-white  rounded-l-full rounded-r-full bg-primary"
                  >
                    <UserIcon className="w-4 h-4  text-white" />
                    <span className="text-sm">
                      Utiliser le Pseudo du marchand
                    </span>
                  </button>
                ) : (
                  <button
                    onClick={() => setScanning(true)}
                    className="p-2 flex w-full justify-center  font-bold gap-4 items-center text-white  rounded-l-full rounded-r-full bg-yellow-500"
                  >
                    <QrCodeIcon className="w-4 h-4 text-white" />
                    <span className="text-sm">
                      Scanner le QR Code du marchand
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* identifier */}
      {displayOperationKioskMethod ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayOperatioKiosknMethod(false);
            setScanning(false)
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            <h2 className="text-lg uppercase font-bold">Recharge</h2>
            {scanning ? (
              <QrScanner
                onDecode={scanQr}
                onError={(error: any) => console.log(error?.message)}
              />
            ) : (
              <>
                {usePseudo ? (
                  <input
                    onChange={($event) => setPhone($event.target.value)}
                    type="text"
                    placeholder="Entrer le pseudo"
                    className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
                  />
                ) : (
                  <>
                    <PhoneInput
                      className="w-fulloutline-none border  rounded-l-full rounded-r-full text-center  border-primary  rounded p-2 text-primary text-xl"
                      placeholder="Enter phone number"
                      value={phone}
                      onChange={setPhone}
                    />
                    <h3 className="text-sm text-red-500">
                      Utiliser les autres options (Scan et pseudo) pour
                      recharger les business!
                    </h3>
                  </>
                )}
                  <button
                  onClick={onValidateKiosqueIdentifier}
                  className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full w-full h-fit text-dark"
                >
                  Continuer
                </button>
                <div className="w-full space-y-2 text-center">
                  <div className="w-full flex justify-between items-center">
                    <hr className="w-2/5" />
                    <span className="uppercase font-serif">ou</span>
                    <hr className="w-2/5" />
                  </div>
                  <div className="w-full flex flex-col gap-4">
                  <button
                      onClick={() => setScanning(true)}
                      className="p-2 flex w-full justify-center  font-bold gap-4 items-center text-white  rounded-l-full rounded-r-full bg-yellow-500"
                    >
                      <QrCodeIcon className="w-4 h-4 text-white" />
                      <span className="text-sm">
                        Scanner le QR Code du receveur
                      </span>
                    </button>
                    {usePseudo ? (
                      <button
                        onClick={() => setUsePseudo(false)}
                        className="font-bold py-2 flex justify-center gap-4 w-full items-center text-white  rounded-l-full rounded-r-full bg-primary"
                      >
                        <PhoneIcon className="w-4 h-4  text-white" />
                        <span className="text-sm">
                          Utiliser le numero de téléphone
                        </span>
                      </button>
                    ) : (
                      <button
                        onClick={() => setUsePseudo(true)}
                        className="font-bold py-2 flex gap-4 w-full justify-center items-center text-white  rounded-l-full rounded-r-full bg-primary"
                      >
                        <UserIcon className="w-4 h-4  text-white" />
                        <span className="text-sm">
                          Utiliser le Pseudo du receveur
                        </span>
                      </button>
                    )}
                
                  </div>
                </div>
              
              </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Amount */}
      {displayAmountModal ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayAmountModal(false);
            setScanning(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2>
            <h5 className="text-sm">
              {" "}
              Voulez-vous effectuer cette opération avec{" "}
              <span className="text-primary text-sm">{receiver.name} </span>?
            </h5>
            <input
              onChange={($event) => setAmount(+$event.target.value)}
              type="number"
              placeholder="Montant en F CFA"
              className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
            />
            <button
              disabled={!amount}
              onClick={onValidateAmount}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
            >
              Effectuer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Invoice */}
      {displayInvoiceModal ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayInvoiceModal(false);
            setScanning(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            {" "}
            <h2 className="text-lg uppercase font-bold">
              Détails de la facture
            </h2>
            <h5 className="text-sm">
              {" "}
              Voulez-vous effectuer cette opération de{" "}
              <span className="text-tertiary">{invoice.amount}</span> à{" "}
              <span className="text-primary text-sm">{invoice.name} </span>?
            </h5>
            <input
              onChange={($event) => setPassword($event.target.value)}
              type="password"
              placeholder="Votre mot de passe"
              className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
            />
            <button
              disabled={!password}
              onClick={onPayInvoice}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
            >
              Payer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Pin */}
      {displayPasswordModal ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayPasswordModal(false);
            setScanning(false);
          }}
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8"
          >
            {" "}
            <h2 className="text-lg uppercase font-bold">Mot de passe</h2>
            <h5 className="text-sm">
              {" "}
              Voulez-vous effectuer cette opération de{" "}
              <span className="text-tertiary">{amount}</span> avec{" "}
              <span className="text-primary text-sm">{receiver.name} </span>?
            </h5>
            <input
              onChange={($event) => setPassword($event.target.value)}
              type="password"
              placeholder="Votre mot de passe"
              className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary py-4 rounded h-10 text-primary "
            />
            <button
              disabled={!password}
              onClick={onMakeOperation}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark"
            >
              Confimer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Form */}
      {displayAccountForm ? (
        <BankAccountForm dismiss={onDismissBankAccountForm} />
      ) : (
        <></>
      )}

      {/* loader */}
      {loader ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayResModal(false);
          }}
        >
          <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
            {/* <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2> */}

            <img src={LoaderImg} />
            <p className="text-gray-500 italic text-sm">{loader?.message}</p>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Business */}
      {/* Details */}
      {displayBusinessInvoiceForm ? (
        <div
          onClick={() => setDisplayBusinessInvoiceForm(false)}
          className="absolute w-full h-screen bg-black bg-opacity-70  z-40 flex flex-col justify-end"
        >
          <div
            onClick={($event) => {
              $event.stopPropagation();
            }}
            className="mx-auto bg-white bg-opacity-100 w-full rounded-t-2xl py-16 z-50 px-4 space-y-8"
          >
            <h2 className="text-center text-lg font-bold">
              Générer une facture
            </h2>

            <input
              onChange={($event) => setAmount(+$event.target.value)}
              type="number"
              placeholder="Montant"
              className="w-full p-4  text-black outline-none rounded-l-full rounded-r-full  text-md  border-2 border-primary"
            />

            <textarea
              onChange={($event) => setDescription($event.target.value)}
              className="w-full  text-black outline-none rounded-l-full rounded-r-full p-4 text-md  border-2 border-primary"
              placeholder="Entrer une description(pseudo)"
            ></textarea>

            <button
              disabled={!amount}
              onClick={onGenerateInvoice}
              className="bg-secondary px-16 py-2 rounded-l-full rounded-r-full text-dark w-full"
            >
              Envoyer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {displayBusinessInvoiceQr ? (
        <div
          className="absolute w-full h-screen bg-black bg-opacity-70  z-40 flex flex-col justify-end"
          onClick={() => setDisplayBusinessInvoiceQR(false)}
        >
          <div className="mx-auto bg-white bg-opacity-100 w-full rounded-t-2xl py-16 z-50">
            <img
              src={invoiceResponse?.qr_code}
              className="w-48 h-48 mx-auto"
              alt=""
            />
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* Response */}
      {displayResModal ? (
        <div
          className="absolute  w-full h-screen bg-black bg-opacity-70 z-40 flex flex-col justify-end"
          onClick={($event) => {
            setDisplayResModal(false);
          }}
        >
          <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
            {/* <h2 className="text-lg uppercase font-bold">
              Entrer le montant <br />
            </h2> */}
            {response?.success ? (
              <CheckCircleIcon className="w-32 h-32 text-secondary" />
            ) : (
              <>
                {" "}
                <InformationCircleIcon className="w-32 h-32 text-red-500" />
                <p className="text-red-500 italic text-sm">
                  {response?.message}
                </p>
              </>
            )}

            <button className="bg-tertiary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark">
              Terminer
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* ContactList */}
      {displayContactsList ? (
        <ContactsList accountType={operationAccountType} cb={selectContact} />
      ) : (
        <></>
      )}
      {/* Page */}
      <div className="h-16 w-full  bg-white p-1 flex justify-between items-center">
        <img src={LogoImg} alt="logo" className="w-16 h-16 rounded-full" />
        <h3 className="text-lg">
          Compte{" "}
          <select value={accountType} onChange={switchAccount}>
            <option value={AccountType.CLIENT}>Client</option>
            <option value={AccountType.BUSINESS}>Marchand</option>
            <option value={AccountType.kIOSQUE}>Kiosque</option>
          </select>
        </h3>
        {displayMenu ? (
          <button
            onClick={() => setDisplayMenu(false)}
            className="rounded-2xl w-12 h-12 flex justify-center items-center border aspect-square"
          >
            <ArrowLeftIcon className="w-8" />
          </button>
        ) : (
          <button
            onClick={() => setDisplayMenu(true)}
            className="rounded-2xl w-12 h-12 flex justify-center items-center border aspect-square"
          >
            <Bars3Icon className="w-8" />
          </button>
        )}
      </div>
      <div className="space-x-4 flex items-center p-2 ">
        {" "}
        <h3 className="text-primary font-bold text-lg">
          Salut {profile?.pseudo} !
        </h3>
      </div>
      <div className="w-full p-8">
        <div
        style={
          {
          backgroundPosition:'center',
          opacity:'0.9',
          backgroundImage:`url(${backGround})`}}
          className={`${
            accountType === AccountType.CLIENT
              ? "bg-secondary text-white"
              : accountType === AccountType.kIOSQUE
              ? "bg-teal-300 text-white "
              : "bg-tertiary text-white"
          } w-full h-36 flex flex-col justify-between shadow-lg rounded-xl  font-serif p-4`}
        >
          <div className="w-full flex justify-between">
            <button onClick={() => switchDisplayQr(true)}>
              <QrCodeIcon className="w-8 h-8" />
            </button>

            {displayBalance ? (
              <span>{balance || 0} F</span>
            ) : (
              <button
                onClick={switchBalanceDisplay}
                className="px-2 border border-white rounded-lg"
              >
                Solde
              </button>
            )}
          </div>
          <div className="w-full flex justify-center text-xl">
            <h3 className="uppercase">{profile?.name}</h3>
          </div>
          <div>
            <h4 className="text-center font-mono text-gray-200">
              {profile?.number}
            </h4>
          </div>
        </div>
      </div>

      {/* Operations */}

      <div className="space-y-4 p-8">
        <h2 className="text-primary text-lg">Operations</h2>
        {accountType === AccountType.CLIENT ? (
          <div className="w-full flex justify-center  items-center gap-2">
            <div
              onClick={() => onInitOperation("transfert")}
              className="w-20 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
            >
              <div className="p-2 rounded-full bg-primary">
                <ArrowPathIcon className="w-8 h-8 mx-auto text-white" />
              </div>

              <h3 className="text-xs text-center">Transfert</h3>
            </div>

            <div
              onClick={() => onInitOperation("deposit")}
              className="w-20 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
            >
              <div className="p-2 rounded-full bg-tertiary">
                <ArrowLeftOnRectangleIcon className="w-8 h-8 mx-auto text-white" />
              </div>

              <h3 className="text-xs text-center">Dépot</h3>
            </div>

            <div
              onClick={() => onInitOperation("withdraw")}
              className="w-20 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
            >
              <div className="p-2 rounded-full bg-red-500">
                <ArrowRightOnRectangleIcon className="w-8 h-8 mx-auto text-white" />
              </div>

              <h3 className="text-xs text-center">Retrait</h3>
            </div>

            <div
              onClick={() => onInitOperation("payment")}
              className="w-20 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
            >
              <div className="p-2 rounded-full bg-secondary">
                <ShoppingCartIcon className="w-8 h-8 mx-auto text-white" />
              </div>

              <h3 className="text-xs text-center">Paiment</h3>
            </div>
          </div>
        ) : accountType === AccountType.kIOSQUE ? (
          <div className="w-full flex justify-center  items-center gap-2">
            <div
              onClick={() => onInitKiiosqueOperation("deposit")}
              className="w-1/3 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
            >
              <div className="p-2 rounded-full bg-tertiary">
                <ArrowLeftOnRectangleIcon className="w-8 h-8 mx-auto text-white" />
              </div>

              <h3 className="text-xs text-center">Recharge</h3>
            </div>

            <div
              onClick={() => onInitKiiosqueOperation("withdraw")}
              className="w-1/3 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
            >
              <div className="p-2 rounded-full bg-red-500">
                <ArrowRightOnRectangleIcon className="w-8 h-8 mx-auto text-white" />
              </div>

              <h3 className="text-xs text-center">Retrait</h3>
            </div>

            {/* <div
              onClick={() => onInitOperation("payment")}
              className="w-20 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
            >
              <div className="p-2 rounded-full bg-secondary">
                <ShoppingCartIcon className="w-8 h-8 mx-auto text-white" />
              </div>

              <h3 className="text-xs text-center">Paiment</h3>
            </div> */}

            <div
              onClick={() => onInitKiiosqueOperation("transfert")}
              className="w-1/3 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
            >
              <div className="p-2 rounded-full bg-primary">
                <LightBulbIcon className="w-8 h-8 mx-auto text-white" />
              </div>

              <h3 className="text-xs text-center">Approvision</h3>
            </div>
          </div>
        ) : accountType === AccountType.BUSINESS ? (
          <div className="w-full flex justify-center  items-center gap-2">
            <div
              onClick={() => setDisplayBusinessInvoiceForm(true)}
              className="w-20 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
            >
              <div className="p-2 rounded-full bg-primary">
                <PaperClipIcon className="w-8 h-8 mx-auto text-white" />
              </div>

              <h3 className="text-xs text-center">Facture</h3>
            </div>

            <div
              onClick={() => onInitOperation("deposit")}
              className="w-20 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
            >
              <div className="p-2 rounded-full bg-tertiary">
                <ArrowLeftOnRectangleIcon className="w-8 h-8 mx-auto text-white" />
              </div>

              <h3 className="text-xs text-center">Recharge</h3>
            </div>

            <div
              onClick={() => onInitOperation("withdraw")}
              className="w-20 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
            >
              <div className="p-2 rounded-full bg-red-500">
                <ArrowRightOnRectangleIcon className="w-8 h-8 mx-auto text-white" />
              </div>

              <h3 className="text-xs text-center">Retrait</h3>
            </div>

            <div
              onClick={() => onInitOperation("payment")}
              className="w-20 h-20 bg-white shadow-lg p-2 rounded-xl flex flex-col items-center justify-between"
            >
              <div className="p-2 rounded-full bg-secondary">
                <ShoppingCartIcon className="w-8 h-8 mx-auto text-white" />
              </div>

              <h3 className="text-xs text-center">Paiment</h3>
            </div>
          </div>
        ) : (
          <div>No operation found for this type of account!</div>
        )}
      </div>

      {/* Historique */}
      <div className="space-y-4 p-8 h-50 overflow-auto">
        <h2 className="text-primary text-lg">Mes dernières opérations</h2>
        <div className="w-full  space-y-4 h-96">
          {operations.map((operation) => (
            <div
              key={operation.id}
              className="w-full bg-white shadow-sm p-2 flex items-center justify-between rounded-xl gap-2"
            >
              <div className="p-2 flex gap-2 items-center">
                <ArrowPathIcon className="w-8 h-8 mx-auto text-primary" />
                <div>
                  <h3 className="text-xs text-primary uppercase">
                  {operation.type}{" "}
                      {operation.user_type === "receiver" ? "de" : "à"}:{" "}
                  </h3>
                 
                  <p className="text-sm text-gray-400">
                   {operation.user_type === "receiver" ?operation.sender_name: operation.receiver_name}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-xs text-gray-500">
                  {operation.date} à {operation.time}
                </p>
                <span className="text-sm text-primary">
                  {+operation.amount} F CFA
                </span>
              </div>
            </div>
          ))}

          {/* <div className="w-full bg-white shadow-sm p-2 flex items-center justify-between rounded-xl gap-2">
            <div className="p-2 flex gap-2 items-center">
              <ShoppingCartIcon className="w-8 h-8 mx-auto text-secondary" />
              <div>
                <h3 className="text-xs text-secondary">Paiment</h3>
                <p className="text-sm text-gray-400">chez Opéra</p>
              </div>
            </div>
            <div className="text-right">
              <p className="text-xs text-gray-500">12/01/2023 à 8:00</p>
              <span className="text-sm text-secondary">3 500 F CFA</span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Home;
