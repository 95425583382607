import React, { useEffect, useState } from "react";
import { useTontine } from "../../store";
import {
  CheckCircleIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  PlusCircleIcon,
  QrCodeIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { ITontine, JoinTontineDTO, TontineDTO } from "../../models";
import Modal from "../../components/utils/Modal";
import { useNavigate } from "react-router-dom";
import { QrScanner } from "@yudiel/react-qr-scanner";

const TontinesView = () => {
  const { getTontines, addNewTotineGroupe, joinTontine } = useTontine();
  const navigate = useNavigate();
  const [tontines, setTontines] = useState<ITontine[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [modalStep, setModalStep] = useState<
    | "start"
    | "join"
    | "joinWithQr"
    | "add"
    | "confirm"
    | "confirmJoin"
    | "success"
    | "error"
  >("start");
  const [error, setError] = useState<string>("");
  const [tontineForm, setTontineForm] = useState<TontineDTO>({
    title: "",
    description: "",
    amount: 0,
    frequency: "monthly",
    number_of_people: 0,
    tontine_type: "manual",
    start_date: "",
    password: "",
    member_transaction_type: "manual",
  });

  const [joinForm, setJoinForm] = useState<JoinTontineDTO>({
    tontine_id: "",
    tontine_mode: "manual",
    password: "",
  });

  useEffect(() => {
    async function fetchData() {
      const data = await getTontines();
      setTontines(data);
    }
    fetchData();
  }, []);

  const navigateToDetails = (id: string) => {
    navigate("/advanced/tontines/details/" + id);
  };

  const changeHandler = (e: any) =>
    setTontineForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const changeJoinHandler = (e: any) =>
    setJoinForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const onAddTontine = () => {
    setModalStep("add");
  };

  const onJoinTontine = () => {
    setModalStep("join");
  };

  const onConfirmTontine = () => {
    setModalStep("confirm");
  };

  const confirmAdd = async () => {
    const res = await addNewTotineGroupe(tontineForm);
    if (res.success) setModalStep("success");
    else {
      setModalStep("error");
      setError(res.data?.message || "Une erreur s'est produite");
      setTimeout(() => {
        setModal(false);
        setModalStep("start");
      }, 3000);
    }
  };

  const confirmJoin = async () => {
    const res = await joinTontine(joinForm);
    // console.log(res);
    if (res.success) {
      setModalStep("success");
      setTimeout(() => {
        setModal(false);
        setModalStep("start");
      }, 3000);
    }
    else {
      setError(res.data?.message || "Une erreur s'est produite");
      setModalStep("error");
    }
  };

  const scanQr = () => {
    setModalStep("joinWithQr");
  };

  return (
    <div className="w-full pt-4 px-4 h-[calc(100vh-120px)] bg-slate-50">
      <div className="w-full flex gap-4">
        <div className="flex items-center border-2 w-full  ">
          <input
            className="w-full py-2 outline-none pl-1"
            placeholder="Rechercher une tontine..."
          />
          <span className="p-3">
            <MagnifyingGlassIcon className="w-4 h-4" />
          </span>
        </div>
        {/* <div className="w-full h-full flex flex-col gap-2 overflow-auto">

        </div> */}
        <div className="text-right p-1">
          <button
            onClick={() => setModal(true)}
            className="px-3 py-2 bg-secondary text-white"
          >
            Ajouter
          </button>
        </div>
      </div>
      {/* List */}
      <div className="h-full py-8 space-y-4">
        {!tontines.length ? (
          <div className="bg-white text-center h-full flex-col justify-center py-52 text-gray-500 italic">
            Aucune tontine disponible
          </div>
        ) : (
          tontines.map((tontine, i) => (
            <div
              key={i}
              className="flex justify-between items-center border-b-2 p-2 border-l-4 border-teal-700"
              onClick={() => navigateToDetails(tontine.id)}
            >
              <div>
                {/* dispay 5 last carachters of id */}
                <h3 className="font-bold text-primary">
                  {tontine.id.slice(-6)}
                </h3>
              </div>
              <div>
                <h4 className="text-md font-semibold">
                  Tontine {tontine.title}
                </h4>
                <p className="text-sm italic text-gray-600 text-wrap">
                  par{" "}
                  <span className="font-semibold text-primary">
                    {tontine.tontine_owner}
                  </span>
                </p>
              </div>
              <div>
                <div className="px-2 py-1 bg-tertiary text-dark rounded text-sm">
                  {tontine.amount} FCFA /{" "}
                  {tontine.frequency === "yearly"
                    ? "an"
                    : tontine.frequency === "monthly"
                    ? "mois"
                    : tontine?.frequency === "biweekly"
                    ? "quinzaine"
                    : tontine?.frequency === "weekly"
                    ? "semaine"
                    : "jour"}
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {modal && (
        <Modal>
          <div className="w-full text-left">
            <button
              onClick={() => {
                setModal(false);
                setModalStep("start");
              }}
              className=" text-red-600"
            >
              Annuler
            </button>
          </div>
          <div className="w-full">
            {modalStep === "start" ? (
              <div className="w-full flex justify-around ">
                <div className="flex flex-col px-4 first:pr-0 text-center">
                  <span onClick={onAddTontine}>
                    <PlusCircleIcon className="w-16 text-secondary" />
                  </span>
                  Créer
                </div>
                <div className="flex flex-col px-4 last:pl-0 ">
                  <span onClick={onJoinTontine}>
                    <QrCodeIcon className="w-16 text-secondary" />
                  </span>
                  Rejoindre
                </div>
              </div>
            ) : modalStep === "join" ? (
              <div>
                <div className="flex flex-col space-y-3">
                  <div className="flex flex-col space-y-2">
                    <label className="font-bold" htmlFor="">
                      Code de la tontine
                    </label>
                    <input
                      onChange={changeJoinHandler}
                      name="tontine_id"
                      value={joinForm.tontine_id}
                      type="text"
                      className="w-full border p-2 rounded"
                    />
                  </div>
                  <div className="flex flex-col space-y-1">
                    <label className="font-bold" htmlFor="">
                      Mode de versement
                    </label>
                    <select
                      onChange={changeJoinHandler}
                      name="tontine_mode"
                      value={joinForm.tontine_mode}
                      className="w-full border p-2 rounded"
                    >
                      <option value=""></option>
                      {[
                        { value: "manual", label: "Manuel" },
                        { value: "automatic", label: "Automatique" },
                      ].map(({ label, value }, idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="pt-4 w-full flex justify-between">
                  <button
                    onClick={scanQr}
                    className="text-center text-md font-bold underline text-primary"
                  >
                    Scanner plutot le code Qr de la tontine
                    {/* <button className="">Scanner</button> */}
                  </button>
                </div>
                <div className="flex justify-around w-full gap-4 px-2 mt-8">
                  <button
                    onClick={() => setModalStep("start")}
                    className="bg-neutral-400 px-4 py-2 rounded text-white w-full"
                  >
                    retour
                  </button>
                  <button
                    onClick={() => setModalStep("confirmJoin")}
                    className="bg-secondary px-4 py-2 rounded text-white w-full"
                    type="submit"
                  >
                    Continuer
                  </button>
                </div>
              </div>
            ) : modalStep === "joinWithQr" ? (
              <div>
                {" "}
                <small>Scannner le Qr Code du Marchand</small>
                <QrScanner
                  onDecode={(data: any) => { setJoinForm((prev) => ({ ...prev, id: data })); setModalStep("confirmJoin"); }}
                  onError={(error: any) => console.log(error?.message)}
                />
              </div>
            ) : modalStep === "add" ? (
              <>
                <div className="space-y-3">
                  <div className="flex flex-col space-y-2">
                    <label className="font-bold" htmlFor="">
                      Date de lancement
                    </label>
                    <input
                      onChange={changeHandler}
                      name="start_date"
                      value={tontineForm.start_date}
                      type="date"
                      className="w-full border p-2 rounded"
                    />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label className="font-bold" htmlFor="">
                      Nom groupe
                    </label>
                    <input
                      onChange={changeHandler}
                      name="title"
                      value={tontineForm.title}
                      type="text"
                      className="w-full border p-2 rounded"
                    />
                  </div>
                  <div className="flex flex-col space-y-1">
                    <label className="font-bold" htmlFor="">
                      Description
                    </label>
                    <input
                      onChange={changeHandler}
                      name="description"
                      value={tontineForm.description}
                      type="text"
                      className="w-full border  p-2 rounded"
                    />
                  </div>
                  <div className="flex flex-col space-y-1">
                    <label className="font-bold" htmlFor="">
                      Prix fixe
                    </label>
                    <input
                      onChange={changeHandler}
                      name="amount"
                      value={tontineForm.amount}
                      type="number"
                      className="w-full border  p-2 rounded"
                    />
                  </div>
                  <div className="flex flex-col space-y-1">
                    <label className="font-bold" htmlFor="">
                      Nombre de personnes
                    </label>
                    <input
                      onChange={changeHandler}
                      name="number_of_people"
                      value={tontineForm.number_of_people}
                      type="number"
                      className="w-full border p-2 rounded"
                    />
                  </div>
                  <div className="flex flex-col space-y-1">
                    <label className="font-bold" htmlFor="">
                      Type de tontine
                    </label>
                    <select
                      onChange={changeHandler}
                      name="frequency"
                      value={tontineForm.frequency}
                      className="w-full border p-2 rounded"
                    >
                      <option value=""></option>
                      {[
                        { value: "monthly", label: "Mensuel" },
                        { value: "weekly", label: "hebdomadaire" },
                      ].map(({ label, value }, idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <label className="font-bold" htmlFor="">
                      Mode de versement
                    </label>
                    <select
                      onChange={changeHandler}
                      name="member_transaction_type"
                      value={tontineForm.member_transaction_type}
                      className="w-full border p-2 rounded"
                    >
                      <option value=""></option>
                      {[
                        { value: "manual", label: "Manuel" },
                        { value: "automatic", label: "Automatique" },
                      ].map(({ label, value }, idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <label className="font-bold" htmlFor="">
                      Mode de cotisation
                    </label>
                    <select
                      onChange={changeHandler}
                      name="tontine_type"
                      value={tontineForm.tontine_type}
                      className="w-full border p-2 rounded"
                    >
                      <option value=""></option>
                      {[
                        { value: "manual", label: "Manuel" },
                        { value: "automatic", label: "Automatique" },
                      ].map(({ label, value }, idx) => (
                        <option key={idx} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex justify-around w-full gap-4 px-2 mt-8">
                  <button
                    onClick={() => setModalStep("start")}
                    className="bg-neutral-400 px-4 py-2 rounded text-white w-full"
                  >
                    retour
                  </button>
                  <button
                    onClick={onConfirmTontine}
                    className="bg-secondary px-4 py-2 rounded text-white w-full"
                    type="submit"
                  >
                    Continuer
                  </button>
                </div>
              </>
            ) : modalStep === "confirm" ? (
              <div className="p-4 space-y-4">
                <input
                  onChange={changeHandler}
                  name="password"
                  type="password"
                  placeholder="Votre mot de passe"
                  className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary p-6 rounded h-10 text-primary text-lg "
                />
                <div className="flex justify-around w-full gap-4 px-2 pt-4">
                  <button
                    onClick={() => setModalStep("add")}
                    className="bg-neutral-400 px-4 py-2 rounded text-white w-full"
                  >
                    retour
                  </button>
                  <button
                    onClick={confirmAdd}
                    className="bg-secondary px-4 py-2 rounded text-white w-full"
                    type="submit"
                  >
                    Créer
                  </button>
                </div>
              </div>
            ) : modalStep === "confirmJoin" ? (
              <div className="p-4 space-y-4">
                <input
                  onChange={changeJoinHandler}
                  name="password"
                  type="password"
                  placeholder="Votre mot de passe"
                  className="w-full outline-none border  rounded-l-full rounded-r-full text-center  border-primary p-6 rounded h-10 text-primary text-lg "
                />
                <div className="flex justify-around w-full gap-4 px-2 pt-4">
                  <button
                    onClick={() => setModalStep("add")}
                    className="bg-neutral-400 px-4 py-2 rounded text-white w-full"
                  >
                    retour
                  </button>
                  <button
                    onClick={confirmJoin}
                    className="bg-secondary px-4 py-2 rounded text-white w-full"
                    type="submit"
                  >
                    Rejoindre
                  </button>
                </div>
              </div>
            ) : modalStep === "success" ? (
              <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
                <>
                  {" "}
                  <CheckCircleIcon className="w-32 h-32 text-secondary" />
                  <p className="text-secondary italic text-sm">
                    Votre tontine a été créée avec succès. Vous pouvez
                    maintenant inviter des membres
                  </p>
                </>

                {/* <button className="bg-tertiary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark">
                  Terminer
                </button> */}
              </div>
            ) : modalStep === "error" ? (
              <div className="mx-auto p-4 rounded-lg bg-white bg-opacity-100 w-full flex flex-col justify-between items-center  z-50 space-y-8 rounded-t-2xl py-8">
                {" "}
                <InformationCircleIcon className="w-32 h-32 text-red-500" />
                <p className="text-red-500 italic text-sm">{error}</p>
                {/* <button className="bg-tertiary px-16 py-2 rounded-l-full rounded-r-full h-fit text-dark">
                Terminer
              </button> */}
              </div>
            ) : (
              <></>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TontinesView;
