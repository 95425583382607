// Become Salarier

import { useApi } from "./api";

export const useEmployement = () => {
  const { $get, $post, $patch, $delete, $response } = useApi();

  const becomeEmployer = async (data: {
    salary_type: "manual" | "automatic";
    frequency: "monthly" | "weekly" | "bi-weekly";
    next_payment_date: string;
    password: string;
  }) => {
    const res = await $post("account/employment/employer/", data);
    return $response(res);
  };

  // Update employer
  const updateEmployer = async (data: {
    salary_type: "manual" | "automatic";
    frequency: "monthly" | "weekly" | "bi-weekly";
    next_payment_date: string;
  }) => {
    const res = await $patch("account/employment/employer/update/", data);
    return $response(res);
  };

  // get employer
    const getEmployer = async () => {
        const res = await $get("account/employer/");
        return $response(res);
    };


    // Delete employer
    const deleteEmployer = async () => {
        const res = await $delete("account/employment/employer/");
        return $response(res);
    };


    // Get employees
    const getEmployees = async () => {
        const res = await $get("account/employee/");
        return $response(res);
    };

    // get employee
    const getEmployee = async (id: string) => {
        const res = await $get(`account/employee/${id}/`);
        return $response(res);
    };

    // Add employee
    const addEmployee = async (data: {
        employee_id: string;
        salary: number;
    }) => {
        console.log(data);
        const res = await $post("account/employee/", data);
        return $response(res);
    };

    // delete employee
    const deleteEmployee = async (id: string) => {
        const res = await $delete(`account/employee/`, { employee_id: id});
        return $response(res);
    };

    // Block or unblock employee
    const toogleEmployeeStatus = async (data: { employee_id: string; password: string}) => {
        const res = await $post(`account/employee/block_or_unblock_employee/`, data);
        return $response(res);
    };

    // Add salary
    const addSalary = async (data: {
        employee_id: string;
        salary: number;
        password: string;
    }) => {
        const res = await $post("transaction/add_salary/", data);
        return $response(res);
    };

    // Pay salary
    const paySalary = async (data: {
        receiver_id: string;
        password: string;
        amount: number;
        description: string;
    }) => {
        const res = await $post("transaction/salary/", data);
        return $response(res);
    };

    // Pay many salaries
    const payManySalaries = async (data: {
        receiver_ids: string[];
        password: string;
        description: string;
    }) => {
        const res = await $post("transaction/salary/pay_many_salaries/", data);
        return $response(res);
    };

  return { 
    becomeEmployer,
    updateEmployer,
    getEmployer,
    deleteEmployer,
    getEmployees,
    getEmployee,
    addEmployee,
    deleteEmployee,
    toogleEmployeeStatus,
    addSalary,
    paySalary,
    payManySalaries
 };
};

export default useEmployement;
